/**
 * @file FormExchange
 */
'use strict';

/**
 * @classname FormExchange
 * @classdesc 
 */
export default class FormExchange {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 */
	constructor(controller){
		this._controller = controller;
	};


	/**
	 * フォーム送信前処理
	 *
	 * @memberof FormExchange
	 * @return {undefined}
	 */
	preProcessing($form) {
		console.log(`${this.constructor.name}.preProcessing`);
		let checked = false;
		const $target = $('.exchange-target');
		let cnt = 0;
		$target.each((idx, e) => {
			const $e = $(e);
			if($e.prop('checked')) checked = false;
			cnt ++;
		});
		console.log(cnt);
		this._controller._currnetMultipleCheckAllFormId = 'form-exchange';
		this._controller.completeMultipleCheckAllPreProcessing($form);
	};
}
