/**
 * @file 組織説明文開閉
 */
'use strict';

import Common from "../../Utils/Common";

/**
 * @classname OrganizationUserList
 * @classdesc 組織説明文開閉
 */
export default class OrganizationUserList {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('OrganizationUserList.constructor');
		this._$e = $e;

		// 右側
		const rightOptions = {
			root: null,
			rootMargin: "0px 0px 0px -80%",
			threshold: 0
		}
		const callbackright = (entries, observer) => {
			entries.forEach(entry => {
				console.log('right')
				if(entry.isIntersecting) {
					entry.target.lastElementChild.classList.add('right')
				}
				else if(!entry.isIntersecting) {
					entry.target.lastElementChild.classList.remove('right')
				}
			})
		}
		const rightObserver = new IntersectionObserver(callbackright, rightOptions);

		$('.anchor-organization-photo').each(function(i,target){
			rightObserver.observe(target)
		})
		// 左側
		const leftOptions = {
			root: null,
			rootMargin: "0px -80% 0px 0px",
			threshold: 0
		}
		const callbackleft = (entries, observer) => {
			entries.forEach(entry => {
				console.log('left')
				if(entry.isIntersecting) {
					entry.target.lastElementChild.classList.add('left')
				}
				else if(!entry.isIntersecting) {
					entry.target.lastElementChild.classList.remove('left')
				}
			})
		}
		const leftObserver = new IntersectionObserver(callbackleft, leftOptions);

		$('.anchor-organization-photo').each(function(i,target){
			leftObserver.observe(target)
		})
	};
}
