/**
 * @file Form送信
 */
"use strict";
// utility class
import Common from "../../Utils/Common";
import FormOrganizationOrder from "./Custom/FormOrganizationOrder";
import FormPoint from "./Custom/FormPoint";
import FormSetUp from "./Custom/FormSetUp";
import FormUserStaff from "./Custom/FormUserStaff";
import FormProfileStaff from "./Custom/FormProfileStaff";
import FormExchange from "./Custom/FormExchange";
import FormWinner from "./Custom/FormWinner";
import FormBoard from "./Custom/FormBoard";

/**
 * @classname Submit
 * @classdesc フォームサブミット
 */
export default class Submit {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - コントローラー
	 */
	constructor($e, controller) {
		console.log("Submit.constructor");
		this._$e = $e;
		this._controller = controller;
		this._form = $e.attr("data-form");
		this._$form = $(this._form);
		this._process = $e.attr("data-process") || null;
		this._timing = $e.attr("data-timing") || null;
		this._submit = true;
		this._validate = false;
		if (this._process === "true") {
			// 個別処理ファイルのアクティベート
			switch (this._form) {
				case "#form-organization-order":
					this._processing = new FormOrganizationOrder(controller);
					break;
				case "#form-point":
					this._processing = new FormPoint(controller);
					break;
				case "#form-setup":
					this._processing = new FormSetUp(controller);
					break;
				case "#form-user-staff":
					this._processing = new FormUserStaff(controller);
					break;
				case "#form-profile-staff":
					this._processing = new FormProfileStaff(controller);
					break;
				case "#form-exchange":
					this._processing = new FormExchange(controller);
					break;
				case "#form-winner":
					this._processing = new FormWinner(controller);
					break;
				case "#form-board":
					this._processing = new FormBoard(controller);
					break;
			}
		}
		this._$e.on("click", (e) => {
			e.preventDefault();
			if (this._$e.hasClass("disabled")) return;
			if (this._$e.prop("disabled")) return;
			if (!$e.hasClass("clicked")) {
				$e.addClass("clicked");
				this._flg = false;
				this.onClick();
			}
		});
		this._flg = true;
		this._controller.on(
			"completeMultipleCheckAllPreProcessing",
			() =>
				this._$form.attr("id") ===
					this._controller._currnetMultipleCheckAllFormId && this.validate()
		);
		this._controller.on("completePreProcessing", () => this.validate());
		this._controller.on("completeValidate", () => this.completeValidate());
		this._controller.on("completePostProcessing", () => this.addMinutes());
		this._controller.on("hasErrorPostProcessing", () => this.initialize());
	}

	/**
	 * クリックのコールバック
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	onClick() {
		console.log("Submit.onClick");
		console.log(this._$e);
		console.log(this._form);
		if (this._process === "true") {
			if (this._timing === "pre" || this._timing === "both") {
				console.log("前処理実施");
				this._processing.preProcessing(this._$form);
			} else {
				console.log("前処理SKIP");
				this.validate(this._$form);
			}
		} else {
			console.log("前処理SKIP");
			this.validate(this._$form);
		}
	}

	/**
	 * バリデーションチェック
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	validate() {
		if (this._validate) return;
		this._validate = true;
		console.log("Submit.validate");
		console.log("バリデーションチェック開始");
		this._controller.validate(this._$form);
	}

	/**
	 * バリデーション完了
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	completeValidate() {
		console.log("Submit.completeValidate");
		console.log("バリデーションチェック完了");
		setTimeout(() => {
			if (this._$form.find(".validate").hasClass("is-invalid"))
				this._submit = false;
			if (this._$form.find(".validate-checkbox").hasClass("is-invalid"))
				this._submit = false;
			if (this._$form !== this._controller._$form) return;
			if (this._submit) {
				console.log("エラーチェック：エラーなし");
				this.postProcessing();
			} else {
				Common.showErrorModal("入力項目に誤りがあります");
				this._flg = true;
				this.initialize();
			}
		}, 300);
	}

	/**
	 * 後処理
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	postProcessing() {
		console.log("Submit.postProcessing");
		console.log(`process: ${this._process}`);
		if (this._process === "true") {
			if (this._timing === "post" || this._timing === "both") {
				console.log("後処理実施");
				this._processing.postProcessing(this._$form);
			} else {
				console.log("後処理SKIP");
				this.addMinutes();
			}
		} else {
			console.log("後処理SKIP");
			this.addMinutes();
		}
	}

	/**
	 * 分を追加する
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	addMinutes() {
		console.log("Submit.addMinutes");
		$("input[data-time=true]").each((idx, e) => {
			let val = $(e).val();
			if (val.length === 16) {
				val += ":00";
				console.log(val);
				$(this).val(val);
			}
		});
		this.submit();
	}

	/**
	 * submit
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	submit() {
		console.log("Submit.submit");
		$(".ignore-validate").removeAttr("name");
		console.log("サブミット");
		this._form = this._$e.attr("data-form");
		this._$form = $(this._form);
		console.log(this._$form);
		setTimeout(() => {
			Common.submit(this._$form);
		}, 200);
		if (
			this._$form.attr("action") === "#" ||
			this._$form.attr("action") === "" ||
			this._$form.attr("action") === "__TBA__"
		) {
			setTimeout(() => this.initialize(), 2000);
		}
	}

	/**
	 * 初期化
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	initialize() {
		console.log("Submit.initialize");
		this._submit = true;
		this._validate = false;
		this._controller._$form = null;
		this._flg = true;
		this._$e.removeClass("clicked");
	}
}
