/**
 * @file 表示タイプ選択時の動作
 */
'use strict';

/**
 * @classname ChangeDisplayType
 * @classdesc 表示タイプ選択時の動作
 */
export default class ChangeDisplayType {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`);
		this._$e = $e;
		this._id = this._$e.attr('id');
		this._$modalTypeTarget = $('.block-display-type-modal-wrap');
		this._$linkTypeTarget = $('.block-display-type-link-wrap');
		this._$e.on('change', () => this.onChange(300));
		this.onChange()
	};


	/**
	 * Clickのコールバック
	 *
	 * @memberof ChangeDisplayType
	 * @return {undefined}
	 */
	 onChange(duration = 0) {
		console.log(`${this.constructor.name}.onChange`);
		if($('.display_type:checked').attr('id') !== this._id) { return }
		if($('.display_type:checked').attr('id') === 'display_type_1'){
			this._$modalTypeTarget.each((idx, e) => {
				$(e)
					.slideDown(duration)
					.find('.validate')
					.removeClass('ignore-validate');
			})
			this._$linkTypeTarget.each((idx, e) => {
				$(e)
					.slideUp(duration)
					.find('.validate')
					.addClass('ignore-validate')
					.removeClass('is-invalid')
					.val('');
			});
			this._$linkTypeTarget.each((idx, e) => {
				$(e)
					.find('.error-tip')
					.removeClass('show')
					.find('.validate')
					.html('.error-tip-inner')
					.html('');
			});
		}else if($('.display_type:checked').attr('id') === 'display_type_2'){
			this._$linkTypeTarget.each((idx, e) => {
				$(e)
					.slideDown(duration)
					.find('.validate')
					.removeClass('ignore-validate');
			})
			this._$modalTypeTarget.each((idx, e) => {
				$(e)
					.slideUp(duration)
					.find('.validate')
					.addClass('ignore-validate')
					.removeClass('is-invalid')
					.val('');
			});
			this._$modalTypeTarget.each((idx, e) => {
				$(e)
					.find('.error-tip')
					.removeClass('show')
					.find('.validate')
					.html('.error-tip-inner')
					.html('');
			});
		}
	};
}
