/**
 * @file 組織を開く
 */
'use strict';
import Sortable from 'sortablejs';

/**
 * @classname ListSort
 * @classdesc 組織を開く
 */
export default class ListSort {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('ListSort.constructor');
		this._$e = $e;
		const id = $e.attr('id');
		this._$list = document.getElementById(id);
		this._sortable = Sortable.create(this._$list, {
			group: id,
			animation: 100,
			handle: '.fa-arrows-alt',
			direction: 'horizontal'
		})
	};
}
