/**
 * @file FormBoard
 */
'use strict';

/**
 * @classname FormBoard
 * @classdesc 
 */
export default class FormBoard {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 */
	constructor(controller){
		this._controller = controller;
	};


	/**
	 * フォーム送信前処理
	 *
	 * @memberof FormBoard
	 * @return {undefined}
	 */
	preProcessing($form) {
		console.log(`${this.constructor.name}.preProcessing`);
		this._controller.createHtml(true);
		$form.removeAttr('target');
		let official = false;
		let cnt = 0;
		const $official = $('#official');
		const $officialBoard = $('.block-official-genre .validate');
		const $normalBoard = $('.block-normal-genre .validate');
		if($official.prop('checked')){
			official = true;
			$officialBoard.each((idx, e) => {
				const $e = $(e);
				if($e.prop('checked')) cnt ++;
			});
		}else{
			$normalBoard.each((idx, e) => {
				const $e = $(e);
				if($e.prop('checked')) cnt ++;
			});
		}
		if(cnt > 3){
			if(official){
				$officialBoard.addClass('is-invalid');
				$('.block-official-genre .error-tip').addClass('show').find('.error-tip-inner').html('<p>ジャンルは3つまでしか選択できません。</p>');
			}else{
				$normalBoard.addClass('is-invalid');
				$('.block-normal-genre .error-tip').addClass('show').find('.error-tip-inner').html('<p>ジャンルは3つまでしか選択できません。</p>');
				$('[data-accordion-type="target"]').each((idx, e) => {
					const $e = $(e);
					if($e.find('.board-genre').prop('checked')) {
						$e.slideDown();
						$e.prev('.anchor-accordion').find('.fas').removeClass('fa-chevron-down').addClass('fa-chevron-up');
					}
				});
			}
		}else if(cnt === 0){
			if(official){
				$officialBoard.addClass('is-invalid');
				$('.block-official-genre .error-tip').addClass('show').find('.error-tip-inner').html('<p>ジャンルが選択されていません。</p>');
			}else{
				$normalBoard.addClass('is-invalid');
				$('.block-normal-genre .error-tip').addClass('show').find('.error-tip-inner').html('<p>ジャンルが選択されていません。</p>');
			}
		}
		$('[name=mode]').val('1');
		// バリデーション前に行う処理を記載（終了）
		this._controller.completePreProcessing($form);
	};


	/**
	 * フォーム送信後処理
	 *
	 * @memberof FormBoard
	 * @return {undefined}
	 */
	postProcessing($form) {
		console.log(`${this.constructor.name}.postProcessing`);

		let official = false;
		let cnt = 0;
		const $official = $('#official');
		const $officialBoard = $('.block-official-genre .validate');
		const $normalBoard = $('.block-normal-genre .validate');
		if($official.prop('checked')){
			official = true;
			$officialBoard.each((idx, e) => {
				const $e = $(e);
				if($e.prop('checked')) cnt ++;
			});
		}else{
			$normalBoard.each((idx, e) => {
				const $e = $(e);
				if($e.prop('checked')) cnt ++;
			});
		}
		if(cnt > 3){
			if(official){
				$officialBoard.each((idx, e) => {
					const $e = $(e);
					console.log($e.attr('id'));
					$e.addClass('is-invalid');
				});
				$('.block-official-genre .error-tip').addClass('show').find('.error-tip-inner').html('<p>ジャンルは3つまでしか選択できません。</p>');
			}else{
				$normalBoard.each((idx, e) => {
					const $e = $(e);
					console.log($e.attr('id'));
					$e.addClass('is-invalid');
				});
				$('.block-normal-genre .error-tip').addClass('show').find('.error-tip-inner').html('<p>ジャンルは3つまでしか選択できません。</p>');
			}
		}else{
			// バリデーション後に行う処理を記載（終了）
			this._controller.completePostProcessing($form);
		}
	};
}
