/**
 * @file STARS作成
 */
'use strict';

import Common from "../../Utils/Common";

/**
 * @classname CreateStars
 * @classdesc STARS作成
 */
export default class CreateStars {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('CreateStars.constructor');
		this._stars = $e.val();
		console.log(this._stars);
		if(this._stars !== '' && this._stars !== '0000000') this.create();
	};


	/**
	 * STARS作成
	 *
	 * @memberof CreateStars
	 * @return {undefined}
	 */
	create() {
		console.log('CreateStars.create');
		let stars = [];
		stars.push(this._stars.substr(0,1));
		stars.push(this._stars.substr(1,1));
		stars.push(this._stars.substr(2,1));
		stars.push(this._stars.substr(3,1));
		stars.push(this._stars.substr(4,1));
		stars.push(this._stars.substr(5,1));
		const oasis = this._stars.substr(6,1);
		let  starsBox = '';
		for(let i = 0; i < 6; i++){
			starsBox += `<span class="stars${stars[i]}"></span>`;
		}
		console.log(starsBox);
		$('.block-item-stars').html(starsBox).find(`.stars${oasis}`).append('<img src="/img/icon-oasis.png" alt="">');
	};
}
