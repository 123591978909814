 /**
 * @file 組織図検索結果
 */
'use strict';

 import Cookies from "js-cookie";
 import Common from '../../Utils/Common';

/**
 * @classname SearchResult
 * @classdesc 組織図検索結果
 */
export default class SearchResult {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('.anchor-search-result')
	 */
	constructor($e) {
		console.log('SearchResult.constructor');
		this._$e = $e;
		this._type = $e.attr('data-type');
		this._id = $e.attr('data-dept-id');
		$e.on('click', () => this.onClick());
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof SearchResult
	 * @return {undefined}
	 */
	onClick() {
		console.log('SearchResult.onClick');
		let $target = $(`[data-id=${this._id}]`);
		const $closestSwitch = $target.closest('.list-item').find('> .anchor-organization-switch');
		const $topLevelSwitch = $target.closest('.list-user-organization-inner').closest('.list-item').find('> .anchor-organization-switch');
		if($target.hasClass('block-inner')) {
			console.log('next level');
			if(!$topLevelSwitch.hasClass('open')) {
				$topLevelSwitch.click();
			}
			if(!$closestSwitch.hasClass('open')){
				$closestSwitch.click();
			}
		}else if($target.hasClass('block-user-organization')) {
			console.log('top level');
			if(!$closestSwitch.hasClass('open')){
				$closestSwitch.click();
			}
		}
		if(this._type === 'name'){
			const userId = this._$e.attr('data-user-id');
			$target = $(`.anchor-organization-photo[data-user-id=${userId}]`);
			setTimeout(()=>{
				$target.addClass('focus');
			}, 1500);
			setTimeout(()=>{
				$target.removeClass('focus');
			}, 2000);
			setTimeout(()=>{
				$target.addClass('focus');
			}, 2500);
			setTimeout(()=>{
				$target.removeClass('focus');
			}, 3000);
			setTimeout(()=>{
				$target.addClass('focus');
			}, 3500);
			setTimeout(()=>{
				$target.removeClass('focus');
			}, 4000);
			setTimeout(()=>{
				$target.addClass('focus');
			}, 4500);
			setTimeout(()=>{
				$target.removeClass('focus');
			}, 5000);
			setTimeout(()=>{
				$target.addClass('focus');
			}, 5500);
			setTimeout(()=>{
				$target.removeClass('focus');
			}, 6000);
		}else if(this._type === 'keyword'){
			let keyword = $('[data-search-type=keyword]').val();
			keyword = Common.zenkaku2Hankaku(keyword);
			console.log(keyword);
			const reg = new RegExp(keyword, 'i');
			const length = keyword.length;
			setTimeout(()=> {
				$target.find('.text-organization-info').each((idx, e) => {
					const $e = $(e);
					const text = $e.html();
					if(text.match(reg)){
						const pos = text.match(reg);
						console.log(pos);
						const start = pos.index;
						const end = pos.index + length;
						const text1 = text.substring(0, start);
						const text2 = text.substring(start, end);
						const text3 = text.substring(end);
						console.log(text1,text2,text3);
						const newText = `${text1}<strong>${text2}</strong>${text3}`;
						$e.html(newText);
						//$e.setSelectionRange(start, end);
						$e.closest('.list-item').find('.anchor-organization-toggle').eq(idx).click();
					}
				});
			},1300);
		}
		setTimeout(() => {
			console.log($target);
			let offsetTop = $target.offset().top - $('header').height();
			if(this._type === 'name') offsetTop = offsetTop - 30;
			console.log(offsetTop);
			$('html, body').animate({scrollTop: offsetTop}, 500, 'swing');
			this.checkOpen();
		},800);
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof SearchResult
	 * @return {undefined}
	 */
	checkOpen() {
		console.log('SearchResult.checkOpen');
		const opened = $('.block-user-organization').map((idx, e) =>{
			const $e =  $(e);
			if($e.hasClass('open')) return $e.attr('data-id');
		}).get().join(',');
		console.log(opened);
		Cookies.set('organizations', opened, { expires: 1/480 });
	};
}
