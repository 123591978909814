/**
 * @file STARS復帰
 */
'use strict';

import Common from "../../Utils/Common";

/**
 * @classname ReturnStars
 * @classdesc STARS復帰
 */
export default class ReturnStars {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('ReturnStars.constructor');
		this._$e = $e;
		this._stars = $e.val();
		if(this._stars !== ''){
			this.init();
		}
	};


	/**
	 * 復帰
	 *
	 * @memberof ReturnStars
	 * @return {undefined}
	 */
	init() {
		console.log('ReturnStars.init');
		console.log(this._stars);
		$('.stars-select').removeClass('active');
		for (let i = 0; i < this._stars.length; i++){
			const color = this._stars.substr(i, 1);
			console.log(`color is ${color}`);
			$('.stars-wrap').eq(i).find(`.stars-select[data-color=${color}]`).addClass('active');
		}
	};
}
