/**
 * @file AnchorFavorite
 */
'use strict';

/**
 * @classname AnchorFavorite
 * @classdesc AnchorFavorite
 */
export default class AnchorFavorite {
	/**
	 * @constructor
	 * @param {jQuery} $e - .anchor-favorite
	 */
	constructor($e, controller) {
		console.log(`${this.constructor.name}.constructor`);
		this._$e = $e;
		this._controller = controller;
		const existsArticleId = $('.article_id').length ? true : false;
		this._boardId = existsArticleId ? $('.article_id').val() : this._$e.attr('data-board-id');
		$e.on('click', () => this.onClick());
		this._controller.on('onFavoriteComplete', () => {
			if(this._boardId == this._controller._boardId) {
				this.favoriteComplete();
			}
		});
		this._controller.on('onError', () => {
			this._controller._boardId = null;
		})
	}

	/**
	 * clickイベントのコールバック
	 *
	 * @memberof AnchorFavorite
	 * @return undefined
	 */
	onClick() {
		console.log(`${this.constructor.name}.onClick`);
		if(this._$e.hasClass('on')){
			this._controller.favorite(this._boardId, false);
		}else{
			this._controller.favorite(this._boardId, true);
		}
	};

	/**
	 * お気に入り処理完了
	 *
	 * @memberof AnchorFavorite
	 * @return undefined
	 */
	favoriteComplete() {
		console.log(`${this.constructor.name}.favoriteComplete`);
		if(this._$e.hasClass('on')){
			console.log('favorite off');
			this._$e.removeClass('on');
		}else{
			console.log('favorite on');
			this._$e.addClass('on');
		}
		// すぐに消すとPCでボタンがonにならない
		setTimeout(() => {
			this._controller._itemId = null;
		}, 2000);
	};
}
