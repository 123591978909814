/**
 * @file ガチャ
 */

import Cookies from 'js-cookie';


/**
 * @classname Gacha
 * @classdesc ガチャ
 */
export default class Gacha {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('Gacha.constructor');
		this._$e = $e;
		this._runGacha = $('.run-gacha');
		this._doubleChance = false;

		// cookieに引いた人がいれば結果画面を表示する必要がある
		let cookie =  Cookies.get('gacha');
		console.log(cookie);
		let gachaUsers = [];
		if(cookie){
			console.log('cookieあり');
			gachaUsers = JSON.parse(cookie);
		}else{
			console.log('cookieなし');
			$('.image-gacha').show();
		}
		let alreadyGacha = false;
		this._url = $('.btn-go-profile').attr('data-href');
		console.log('cookieからの取得');
		console.log(gachaUsers);
		if(gachaUsers && gachaUsers.length > 0){
			console.log('既にガチャ引いている');
			for(let i = 0; i < gachaUsers.length; i++){
				console.log(gachaUsers[i], this._url);
				if(gachaUsers[i] === this._url) {
					console.log('同じユーザーを引いている');
					alreadyGacha = true;
				}
			}
		}
		if(gachaUsers.length > 0) {
			console.log('cookieに1人以上引いた人がいる');
			this._gachaUsers = gachaUsers;
		}
		this._gachaUsers = [];
		this._gachaUsers.push(this._url);
		console.log('profile遷移用リスト');
		console.log(this._gachaUsers);
		Cookies.set('gacha', JSON.stringify(this._gachaUsers), { expires: 1/24 });
		$('.btn-go-profile').on('click', () => this.goProfile());
		$('.btn-retry').on('click', () => this.retryGacha());
		/*$(window).on('load', () => {
			console.log('画像の読み込みが完了しました');
			if(alreadyGacha){
				$('#particles-js').remove();
				this.showResult();
			}else{
				this.runGacha();
			}
		});*/
		setTimeout(() => {
			console.log('画像の読み込みが失敗しました');
			if(alreadyGacha){
				$('#particles-js').remove();
				this.showResult();
			}else{
				this.runGacha();
			}
		},500);

		this._canvas = document.querySelector("#particles-js");
		this._ctx = this._canvas.getContext("2d");

	};


	/**
	 * ガチャ回す
	 *
	 * @memberof Gacha
	 * @return {undefined}
	 */
	runGacha() {
		console.log('Gacha.runGacha');
		console.log($('#prize_name').val());
		// Configuration, Play with these
		if($('#prize_name').val() !== '') {
			console.log('double chance');
			this._doubleChance = true;

			var canvas = document.querySelector("#particles-js"),
				ctx = canvas.getContext("2d");

			canvas.width = window.innerWidth;
			canvas.height = window.innerHeight;

			var config = {
				particleNumber: 800,
				maxParticleSize: 10,
				maxSpeed: 50,
				colorVariation: 10
			};

			var colorPalette = {
				bg: { r: 255, g: 255, b: 255 },
				matter: [
					{ r: 235, g: 164, b: 43 }, // orange
					{ r: 224, g: 99, b: 121 }, // rockDust
					{ r: 234, g: 226, b: 123 }, // solorFlare
					{ r: 100, g: 181, b: 173 }, // totesASun
					{ r: 80, g: 167, b: 193 }
				]
			};

			var particles = [],
				centerX = canvas.width / 2,
				centerY = canvas.height / 2,
				drawBg,
				drawBg = function (ctx, color) {
					ctx.fillStyle = "rgb(" + color.r + "," + color.g + "," + color.b + ")";
					ctx.fillRect(0, 0, canvas.width, canvas.height);
				};

			var Particle = function (x, y) {
				this.x = x || Math.round(Math.random() * canvas.width);
				this.y = y || Math.round(Math.random() * canvas.height);
				this.r = Math.ceil(Math.random() * config.maxParticleSize);
				this.c = colorVariation(
					colorPalette.matter[Math.floor(Math.random() * colorPalette.matter.length)],
					true
				);
				this.s = Math.pow(Math.ceil(Math.random() * config.maxSpeed), 0.7);
				this.d = Math.round(Math.random() * 360);
			};

			var colorVariation = function (color, returnString) {
				var r, g, b, a, variation;
				r = Math.round(
					Math.random() * config.colorVariation - config.colorVariation / 2 + color.r
				);
				g = Math.round(
					Math.random() * config.colorVariation - config.colorVariation / 2 + color.g
				);
				b = Math.round(
					Math.random() * config.colorVariation - config.colorVariation / 2 + color.b
				);
				a = Math.random() + 0.5;
				if (returnString) {
					return "rgba(" + r + "," + g + "," + b + "," + a + ")";
				} else {
					return { r, g, b, a };
				}
			};

			var updateParticleModel = function (p) {
				var a = 180 - (p.d + 90); // find the 3rd angle
				p.d > 0 && p.d < 180
					? (p.x += (p.s * Math.sin(p.d)) / Math.sin(p.s))
					: (p.x -= (p.s * Math.sin(p.d)) / Math.sin(p.s));
				p.d > 90 && p.d < 270
					? (p.y += (p.s * Math.sin(a)) / Math.sin(p.s))
					: (p.y -= (p.s * Math.sin(a)) / Math.sin(p.s));
				return p;
			};

			var drawParticle = function (x, y, r, c) {
				ctx.beginPath();
				ctx.fillStyle = c;
				ctx.arc(x, y, r, 0, 2 * Math.PI, false);
				ctx.fill();
				ctx.closePath();
			};

			var cleanUpArray = function () {
				particles = particles.filter((p) => {
					return p.x > -100 && p.y > -100;
				});
			};

			var initParticles = function (numParticles, x, y) {
				console.log(`initParticles: ${x}/${y}`);
				for (let i = 0; i < numParticles; i++) {
					particles.push(new Particle(x, y));
				}
				particles.forEach((p) => {
					drawParticle(p.x, p.y, p.r, p.c);
				});
			};

			window.requestAnimFrame = (function () {
				return (
					window.requestAnimationFrame ||
					window.webkitRequestAnimationFrame ||
					window.mozRequestAnimationFrame ||
					function (callback) {
						window.setTimeout(callback, 1000 / 60);
					}
				);
			})();

			var frame = function () {
				drawBg(ctx, colorPalette.bg);
				particles.map((p) => {
					return updateParticleModel(p);
				});
				particles.forEach((p) => {
					drawParticle(p.x, p.y, p.r, p.c);
				});
				window.requestAnimFrame(frame);
			};

			setTimeout(() => {
				$('#particles-js').animate({'opacity': 1}, '500');
				frame();
				initParticles(config.particleNumber, centerX, centerY);
			},4000);

			setTimeout(() => {
				$('#particles-js').fadeOut(1000);
			},4500);
			setTimeout(() => this.confetti(), 6000)
			setTimeout(() => this.showDoubleChance(),8000);

		}else{
			$('#particles-js').remove();
		}
		$('.image-gacha').attr('src', '/img/gacha.gif').show();
		console.log('ガチャ回す画面');
		setTimeout(() => {
			$('.block-gacha-profile .text').fadeIn();
			$('.block-gacha-profile .btn-user').fadeIn();
			$('.profile-img').fadeIn();
			setTimeout(()=> {
				$('.btn-retry').addClass('on');
			},500);
			if(!this._doubleChance){
				this.confetti();
			}
		}, 6000);
	};


	/**
	 * ダブルチャンス表示
	 *
	 * @memberof Gacha
	 * @return {undefined}
	 */
	showDoubleChance() {
		console.log('Gacha.showDoubleChance');
		$('#modal-double').addClass('show');
		this.confetti();
	};


	/**
	 * 結果表示
	 *
	 * @memberof Gacha
	 * @return {undefined}
	 */
	showResult() {
		console.log('Gacha.showResult');
		console.log('結果画面');
		$('.image-gacha').attr('src', '/img/gacha-result.png').show();
		$('.block-gacha-profile .text').show();
		$('.block-gacha-profile .btn').show();
		$('.profile-img').show();
		$('.btn-retry').addClass('on');
	};


	/**
	 * ガチャ回す
	 *
	 * @memberof Gacha
	 * @return {undefined}
	 */
	retryGacha() {
		if(!this._runGacha.hasClass('secondTime')) {
			this._runGacha.addClass('secondTime')
			console.log('Gacha.retryGacha');
			Cookies.remove('gacha');
			const href = $('.btn-retry').attr('data-href');
			location.href = href;
		}else {
			console.log("secondTime")
			return false;
		}
	};


	/**
	 * プロフィールページへ遷移
	 *
	 * @memberof Gacha
	 * @return {undefined}
	 */
	goProfile() {
		console.log('Gacha.goProfile');
		location.href = this._url;
	};

	/**
	 * 紙吹雪
	 *
	 * @memberof Gacha
	 * @return {undefined}
	 */
	confetti() {
		console.log('Gacha.confetti');
		const duration = 15 * 1000;
		const animationEnd = Date.now() + duration;
		const defaults = {startVelocity: 30, spread: 360, ticks: 60, zIndex: 0};

		function randomInRange(min, max) {
			return Math.random() * (max - min) + min;
		}

		const interval = setInterval(function () {
			const timeLeft = animationEnd - Date.now();

			if (timeLeft <= 0) {
				return clearInterval(interval);
			}

			const particleCount = 50 * (timeLeft / duration);
			// since particles fall down, start a bit higher than random
			confetti(Object.assign({}, defaults, {
				particleCount,
				origin: {x: randomInRange(0.1, 0.3), y: Math.random() - 0.2},
				zIndex: 1200
			}));
			confetti(Object.assign({}, defaults, {
				particleCount,
				origin: {x: randomInRange(0.7, 0.9), y: Math.random() - 0.2},
				zIndex: 1200
			}));
		}, 250);
	};

}
