/**
 * @file FormSetUp
 */
'use strict';

/**
 * @classname FormSetUp
 * @classdesc 登録
 */
export default class FormSetUp {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 */
	constructor(controller){
		this._controller = controller;
	};


	/**
	 * フォーム送信前処理
	 *
	 * @memberof FormRegistration
	 * @return {undefined}
	 */
	preProcessing($form) {
		console.log('FormSetUp.preProcessing');
		// バリデーション後に行う処理を記載（開始）

		this._stars = [];
		let zeroCnt = 0;
		$('.stars-wrap .stars-selected .stars-select.active').each((idx, e) => {
			const $e = $(e);
			const color = $e.attr('data-color');
			if(color === '0') zeroCnt ++;
			this._stars.push(color);
		});
		console.log(this._stars);
		if(zeroCnt === 7){
			console.log('no input');
		}else if(zeroCnt > 0){
			console.log('non-selected is existed');
			this.showError();
		}else if(zeroCnt === 0){
			let tmp = this._stars.slice(0, -1);
			console.log(tmp);
			tmp = [...new Set(tmp)];
			console.log(tmp);
			if(tmp.length !== 6){
				console.log('duplicate is existed');
				this.showError();
			}
		}

		const catchCopy1 = $('.input-dummy1').text();
		const catchCopy2 = $('.input-dummy2').text();
		if($('body').hasClass('page-user-setup')){
			if(catchCopy1 === '未選択' || catchCopy2 === '未選択'){
				$('.input-dummy').addClass('is-invalid');
			}
		}else{
			if((catchCopy1 === '未選択' && catchCopy2 !== '未選択') || (catchCopy1 !== '未選択' && catchCopy2 === '未選択')){
				$('.input-dummy').addClass('is-invalid');
			}
		}

		// バリデーション後に行う処理を記載（終了）
		this._controller.completePreProcessing($form);
	};


	/**
	 * エラー表示
	 *
	 * @memberof FormSetUp
	 * @return {undefined}
	 */
	showError() {
		console.log('FormSetUp.showError');
		$('.stars-wrap')
			.addClass('is-invalid')
			.closest('.form-content')
			.find('.error-tip')
			.addClass('show')
			.find('.error-tip-inner')
			.html('<p>STARSの設定が不正です。</p>');
	};


	/**
	 * フォーム送信後処理
	 *
	 * @memberof FormSetUp
	 * @return {undefined}
	 */
	postProcessing($form) {
		console.log('FormSetUp.postProcessing');
		// バリデーション後に行う処理を記載（開始）

		let starsValue = '';
		for(let i = 0; i < this._stars.length; i++){
			starsValue += this._stars[i];
		}
		$('#stars').val(starsValue);

		// バリデーション後に行う処理を記載（終了）
		this._controller.completePostProcessing($form);
	};
}
