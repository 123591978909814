/**
 * @file BusinessBoardGenre
 */
"use strict";

/**
 * @classname BusinessBoardGenre
 * @classdesc BusinessBoardGenre
 */
export default class BusinessBoardGenre {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log("BusinessBoardGenre.constructor");
		this._$e = $e;
		this._target = this._$e.data("target");
		$e.on("click", () => this.onClick());
	}

	/**
	 * クリックのコールバック
	 *
	 * @memberof BusinessBoardGenre
	 * @return {undefined}
	 */
	onClick() {
		console.log("BusinessBoardGenre.onClick");

		// 6つ以上の宛先が選択されたかどうかをチェック
		const $deptCheckboxes = $(".dept_id");
		const selectedDeptCount = $deptCheckboxes.filter(":checked").length;

		// エラーメッセージを表示
		if (selectedDeptCount > 5) {
			// エラーメッセージを表示
			$(".error-tip-checkbox")
				.addClass("show")
				.find(".error-tip-inner")
				.html("<p>宛先は5つまでしか選択できません。</p>");

			// エラーのカラーを変更
			$deptCheckboxes.filter(":checked").addClass("is-invalid");
			$deptCheckboxes.filter(":checked").css("color", "#e34958");
			//カウントが0の時にエラーを表示
		} else if (selectedDeptCount === 0) {
			$deptCheckboxes.addClass("is-invalid");
			$(".error-tip-checkbox")
				.addClass("show")
				.find(".error-tip-inner")
				.html("<p>宛先が選択されていません。</p>");
		} else {
			// エラーメッセージを非表示
			$(".error-tip-checkbox")
				.removeClass("show")
				.find(".error-tip-inner")
				.text("");

			// エラーのカラーをリセット
			$deptCheckboxes.removeClass("is-invalid");
			$deptCheckboxes.css("color", "");
		}
	}
}
