/**
 * @file 商品モーダルの内容書き換え
 */
'use strict';

/**
 * @classname ApplyModal
 * @classdesc モダール内容書き換え
 */
export default class ApplyModal {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('ApplyModal.constructor');
		this._$e = $e;
		this._controller = controller;
		this._reloadBtn = this._$e.parent().find('.btn-complete-modal')
		this._contentBlock = $('.block-exchange-modal');
		this._completeText =$('.text-exchange-complete');
		this._closeBtn =$('.modal-header').find('.close');
		$e.on('click', () => this.onClick());
		this._controller.on('applyPrizeComplete', () => this.onApplyPrizeComplete());
	};
 
	/**
	 * clickのコールバック
	 *
	 * @memberof ApplyModal
	 * @return {undefined}
	 */
	onClick() {
		console.log('ApplyModal.onClick');
		this._$e.attr('disabled', 'disabled')
		this._prizeId = this._$e.attr('prize-id')
		this._controller.applyPrize(this._prizeId);
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof ApplyModal
	 * @return {undefined}
	 */
	 onApplyPrizeComplete() {
		console.log('onApplyPrizeComplete実行');
		this._closeBtn.addClass('hide')
		
		this._reloadBtn.removeClass('hide')
		this._$e.addClass('hide');
		this._completeText.removeClass('hide')
		this._contentBlock.addClass('hide')
	};

}