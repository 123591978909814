/**
 * @file Enterでsubmit
 */
'use strict';

import Common from "../../Utils/Common";

/**
 * @classname EnterSubmit
 * @classdesc Enterでsubmit
 */
export default class EnterSubmit {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('EnterSubmit.constructor');
		this._$e = $e;
		$e.on(('keypress'), e => this.onKeypress(e));
	};


	/**
	 * keypressのコールバック
	 *
	 * @memberof EnterSubmit
	 * @return {undefined}
	 */
	onKeypress(e) {
		console.log('EnterSubmit.onKeypress');
		const key = e.keyCode || e.charCode || 0;
		if (key === 13) {
			let type = 'name';
			let url = Common.getCurrentURL();
			if(e.currentTarget.getAttribute('data-search-type')==='keyword') type = 'keyword';
			console.log(type);
			if(type === 'name'){
				const name =$('[data-search-type=name]').val();
				console.log(name);
				if(name === '') return;
				url = url + `?type=name&search=${name}`;
			}else{
				const keyword =$('[data-search-type=keyword]').val();
				if(keyword === '') return;
				if(keyword.length > 1){
					url = url + `?type=keyword&search=${keyword}`;
				}else{
					alert('最低2文字以上入力してください');
					return;
				}
			}
			console.log(url);
			location.href = url;
		}
	};
}

