/**
 * @file 掲示板新着チェック
 */
'use strict';

import Common from "../../Utils/Common";

/**
 * @classname CheckBoardNew
 * @classdesc 掲示板新着チェック
 */
export default class CheckBoardNew {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log(`CheckBoardNew.constructor`);
		this._$e = $e;
		const today = new Date();
		const attr = $e.attr('data-post-date');
		let tmp = attr.split(' ');
		let date = tmp[0].split('-');
		let time = tmp[1].split(':');
		const year = date[0];
		const month = date[1] - 1;
		const day = date[2];
		const hour = time[0];
		const min = time[1];
		const sec = time[2];
		const postDate = new Date(year, month, day,hour, min, sec);
		console.log(today, postDate);
		const diff = (today.getTime() - postDate.getTime()) / (60*60*1000);
		if(diff <= 24){
			$e.addClass('new');
			console.log('new');
		}
	};
}
