/**
 * @file ページネーション
 */
'use strict';

import Common from "../../Utils/Common";

/**
 * @classname Stars
 * @classdesc ページネーション
 */
export default class Stars {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('Stars.constructor');
		this._$e = $e;
		this._$selected = $e.find('.stars-selected');
		this._$options = $e.find('.stars-options');
		$e.on('click', () => this.onClick());
		$e.find('.stars-option').on('click', e => this.select(e));
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof Stars
	 * @return {undefined}
	 */
	onClick() {
		console.log('Stars.onClick');
		if(this._$options.hasClass('active')){
			this._$options.removeClass('active');
		}else{
			this._$options.addClass('active');
		}
	};


	/**
	 * STARS選択
	 *
	 * @memberof Stars
	 * @return {undefined}
	 */
	select(e) {
		console.log('Stars.select');
		e.preventDefault();
		e.stopPropagation();
		const $e = $(e.currentTarget);
		const selected = $e.attr('data-color');
		this._$options.removeClass('active');
		this._$selected.find('.stars-select').removeClass('active');
		this._$selected.find(`.stars-select[data-color=${selected}]`).addClass('active');
		$('.stars-wrap')
			.removeClass('is-invalid')
			.closest('.form-content')
			.find('.error-tip')
			.removeClass('show')
			.find('.error-tip-inner')
			.html('');
	};
}
