/**
 * @file お知らせもっと見る
 */
'use strict';

import Common from "../../Utils/Common";

/**
 * @classname InfoMore
 * @classdesc お知らせもっと見る
 */
export default class InfoMore {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('InfoMore.constructor');
		this._$e = $e;
		this._$list = $e.closest('.block-top3').find('.list');
		if($(window).width() >= 768){
			this._$target = $e.closest('.block-top3').find('.list-item.hide-pc');
		}else{
			this._$target = $e.closest('.block-top3').find('.list-item.hide-sp');
		}
		$e.on('click', () => this.onClick());
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof InfoMore
	 * @return {undefined}
	 */
	onClick() {
		console.log('InfoMore.onClick');
		if(this._$list.hasClass('full')){
			this._$target.fadeOut();
			this._$list.removeClass('full');
			this._$e.removeClass('open').html('<span></span>もっと見る');
		}else{
			console.log('open');
			this._$target.fadeIn();
			this._$list.addClass('full');
			this._$e.addClass('open').html('<span></span>閉じる');
		}
	};
}
