/**
 * @file /**
 * @file SwapContributor
 */
'use strict';

import { cibLgtm } from "@coreui/icons";

/**
 * @classname SwapContributor
 * @classdesc SwapContributor
 */
export default class SwapContributor {
	/**
	 * @constructor
	 * @param {jQuery} $e - .swap-contributor
	 */
	constructor($e) {
		console.log('SwapContributor.constructor');
		this._$e = $e;
		this._$coAuthor = this._$e.closest('.form-group').find('.co_author');
		this._$PostingAsDivision = $('.posting_as_division');
		this._$PostingAsDivisionLabel = this._$PostingAsDivision.closest('.form-group').find('.input-checkbtn-label');
		console.log(this._$PostingAsDivision)
		this._$coAuthor.on('change', e => this.onChange(e));
		this._$PostingAsDivisionLabel.on('click', e => this.onClick(e));
		this.onChange();
	};

	/**
	 * this._$coAuthor の change コールバック
	 *
	 * @memberof SwapContributor
	 * @return {undefined}
	 */
	 onChange() {
		console.log('SwapContributor.onChange');
		if (this._$coAuthor.val() && !this._$PostingAsDivision.prop('checked')) {
			this._$e.attr('disabled', false);
		} else {
			this._$e.attr('disabled', true);
			this._$e.removeAttr('checked').prop('checked',false).change();
		}
	};

	/**
	 * this._$PostingAsDivisionLabel の click コールバック
	 *
	 * @memberof SwapContributor
	 * @return {undefined}
	 */
	 onClick() {
		console.log('SwapContributor.onClick');
		if (this._$coAuthor.val() && !this._$PostingAsDivision.prop('checked')) {
			this._$e.attr('disabled', false);
		} else {
			this._$e.attr('disabled', true);
			this._$e.removeAttr('checked').prop('checked',false).change();
		}
	};
}