/**
 * @file 賞品タイプ選択時の動作
 */
'use strict';

/**
 * @classname ChangePrizeType
 * @classdesc 賞品タイプ選択時の動作
 */
export default class ChangePrizeType {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`);
		this._$e = $e;
		this._id = this._$e.attr('id');
		this._$lotteryTargets = $('.block-prize-type-lottery-wrap, .block-display-type-wrap, .block-number-of-people-wrap');
		this._$exchangeTargets = $('.block-prize-type-exchange-wrap, .block-stock-type-wrap');
		this._$e.on('change', () => this.onChange(300));
		this.onChange()
	};


	/**
	 * Clickのコールバック
	 *
	 * @memberof ChangePrizeType
	 * @return {undefined}
	 */
	onChange(duration = 0) {
		console.log(`${this.constructor.name}.onChange`);
		if($('.prize_type:checked').attr('id') !== this._id) { return }
		if($('.prize_type:checked').attr('id') === 'type_1'){
			this._$lotteryTargets.each((idx, e) => {
				$(e)
					.slideDown(duration)
					.find('.validate')
					.removeClass('ignore-validate');
			});
			this._$exchangeTargets.each((idx, e) => {
				$(e)
					.slideUp(duration)
					.find('.validate')
					.addClass('ignore-validate')
					.removeClass('is-invalid')
					.val('');
			});
			this._$exchangeTargets.each((idx, e) => {
				$(e)
					.find('.error-tip')
					.removeClass('show')
					.find('.validate')
					.html('.error-tip-inner')
					.html('');
			});
		}else if($('.prize_type:checked').attr('id') === 'type_2'){
			$('.display_type').prop('checked', false);
			$('#display_type_1').prop('checked', true);
			$('.display_type').trigger('change');
			this._$exchangeTargets.each((idx, e) => {
				$(e)
					.slideDown(duration)
					.find('.validate')
					.removeClass('ignore-validate');
			})
			this._$lotteryTargets.each((idx, e) => {
				$(e)
					.slideUp(duration)
					.find('.validate')
					.addClass('ignore-validate')
					.removeClass('is-invalid')
					.val('');
			});
			this._$lotteryTargets.each((idx, e) => {
				$(e)
					.find('.error-tip')
					.removeClass('show')
					.find('.validate')
					.html('.error-tip-inner')
					.html('');
			});
			$('.stock_type').trigger('change');
		}
	};
}
