/**
 * @file 文字カウンター
 */
'use strict';

/**
 * @classname ChangeBirthday
 * @classdesc 誕生日表示・非表示
 */
export default class ChangeBirthday {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('ChangeBirthday.constructor');
		this._$e = $e;
		this._$target = $('.block-birthday-wrap');
		$e.on('click', () => this.onClick());
		this.onClick();
	};


	/**
	 * Clickのコールバック
	 *
	 * @memberof ChangeBirthday
	 * @return {undefined}
	 */
	onClick() {
		console.log('ChangeBirthday.onClick');
		if($('[name=show_birthday]:checked').next('label').text() === '表示'){
			this._$target
				.slideDown()
				.find('.validate')
				.removeClass('ignore-validate');
		}else{
			this._$target
				.slideUp()
				.find('.validate')
				.addClass('ignore-validate')
				.removeClass('is-invalid')
				.val('');
			this._$target
				.find('.error-tip')
				.removeClass('show')
				.find('.validate')
				.html('.error-tip-inner')
				.html('');
		}
	};
}
