/**
 * @file ガチャポイントタイプ選択時の動作
 */
'use strict';

/**
 * @classname ChangePointType
 * @classdesc ガチャポイントタイプ選択時の動作
 */
export default class ChangePointType {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`);
		this._$e = $e;
		this._$blockAdd = $('.block-type-add-wrap');
		this._$blockMagnification = $('.block-type-magnification-wrap');
		this._$e.on('change', () => this.onChange(300));
		this.onChange()
	};


	/**
	 * Clickのコールバック
	 *
	 * @memberof ChangePointType
	 * @return {undefined}
	 */
	onChange(duration = 0) {
		console.log(`${this.constructor.name}.onChange`);
		if($('.point_type:checked').attr('id') === 'type_1') {
			this._$blockAdd.slideDown();
			$('#add_point').removeClass('ignore-validate');
			this._$blockMagnification
				.slideUp()
				.find('.error-tip')
				.removeClass('show')
				.find('.error-tip-inner')
				.text('');
			$('#magnification_rate')
				.val('')
				.removeClass('is-invalid')
				.addClass('ignore-validate');
		}else{
			this._$blockMagnification.slideDown();
			$('#magnification_rate').removeClass('ignore-validate');
			this._$blockAdd
				.slideUp()
				.find('.error-tip')
				.removeClass('show')
				.find('.error-tip-inner')
				.text('');
			$('#add_point')
				.val('')
				.removeClass('is-invalid')
				.addClass('ignore-validate');
		}
	};
}
