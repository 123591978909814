/**
 * Alert block for the Editor.js.
 *
 * @author Vishal Telangre
 * @license MIT
 */

/**
 * Build styles
 */
require('./index.css').toString();

/**
 * Import Tool's icon
 */
import ToolboxIcon from './icon.svg';

/**
 * @class Alert
 * @classdesc Alert Tool for Editor.js
 * @property {AlertData} data - Alert Tool`s input and output data
 * @property {object} api - Editor.js API instance
 *
 * @typedef {object} AlertData
 * @description Alert Tool`s input and output data
 * @property {string} type - Alert type
 * @property {string} alignment - Alert alignment
 * @property {string} display - Alert display
 * @property {string} message - Alert message
 *
 * @typedef {object} AlertConfig
 * @description Alert Tool`s initial configuration
 * @property {string} defaultType - default Alert type
 * @property {string} messagePlaceholder - placeholder to show in Alert`s message input
 */
export default class Alert {
  /**
   * Get Toolbox settings
   *
   * @public
   * @returns {string}
   */
  static get toolbox() {
    return {
      icon: ToolboxIcon,
      title: 'Alert',
    };
  }

  /**
   * Allow to press Enter inside the Alert block
   * @public
   * @returns {boolean}
   */
  static get enableLineBreaks() {
    return true;
  }

  /**
   * Default Alert alignment
   *
   * @public
   * @returns {string}
   */
  static get DEFAULT_ALIGNMENT() {
    return 'left';
  }

  /**
   * Default Alert type
   *
   * @public
   * @returns {string}
   */
  static get DEFAULT_TYPE() {
    return 'info';
  }

  /**
   * Default Alert display
   *
   * @public
   * @returns {string}
   */
  static get DEFAULT_DISPLAY() {
    return 'block';
  }

  /**
   * Default placeholder for Alert message
   *
   * @public
   * @returns {string}
   */
  static get DEFAULT_MESSAGE_PLACEHOLDER() {
    return 'Type here...';
  }

  /**
   * Supported Alert alignment
   *
   * @public
   * @returns {array}
   */
  static get ALERT_ALIGNMENTS() {
    return [
      'left',
      'center',
      'right',
    ];
  }

  /**
   * Supported Alert types
   *
   * @public
   * @returns {array}
   */
  static get ALERT_TYPES() {
    return [
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'danger',
      'light',
      'dark',
    ];
  }

  /**
   * Supported Alert display
   *
   * @public
   * @returns {array}
   */
  static get ALERT_DISPLAYS() {
    return [
      'block',
      'inline-block',
    ];
  }

  /**
   * Alert Tool`s styles
   *
   * @returns {Object}
   */
  get CSS() {
    return {
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,
      parent: 'cdx-wrap',
      wrapper: 'cdx-alert',
      alignmentButton: 'cdx-button-alignment',
      buttonForAlignment: (alignment) => `cdx-${alignment}`,
      wrapperForAlignment: (alignment) => `cdx-wrap-${alignment}`,
      typeButton: 'cdx-button-type',
      wrapperForType: (type) => `cdx-alert-${type}`,
      displayButton: 'cdx-button-display',
      buttonForDisplay: (display) => `cdx-${display}`,
      wrapperForDisplay: (display) => `cdx-alert-${display}`,
      blankButton: 'cdx-button-blank',
      message: 'cdx-alert__message',
    };
  }

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {AlertData} data — previously saved data
   * @param {AlertConfig} config — user config for Tool
   * @param {Object} api - Editor.js API
   * @param {boolean} readOnly - read only mode flag
   */
  constructor({ data, config, api, readOnly }) {
    this.api = api;

    this.defaultType = config.defaultType || Alert.DEFAULT_TYPE;
    this.defaultAlignment = config.defaultAlignment || Alert.DEFAULT_ALIGNMENT;
    this.defaultDisplay = config.defaultDisplay || Alert.DEFAULT_DISPLAY;
    this.messagePlaceholder =
      config.messagePlaceholder || Alert.DEFAULT_MESSAGE_PLACEHOLDER;

    this.data = {
      type: Alert.ALERT_TYPES.includes(data.type)
        ? data.type
        : this.defaultType,
      message: data.message || '',
      alignment: Alert.ALERT_ALIGNMENTS.includes(data.alignment)
        ? data.alignment
        : this.defaultAlignment,
      display: Alert.ALERT_DISPLAYS.includes(data.display)
        ? data.display
        : this.defaultDisplay,
    };

    this.container = undefined;
    
    this.readOnly = readOnly;
  }
  
  /**
   * Returns true to notify the core that read-only mode is supported
   *
   * @return {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Create Alert Tool container
   *
   * @returns {Element}
   */
  render() {
    const containerClasses = [
      this.CSS.wrapper,
      this.CSS.wrapperForType(this.data.type),
      this.CSS.wrapperForDisplay(this.data.display),
    ];

    this.node = this._make('div', [this.CSS.parent, this.CSS.wrapperForAlignment(this.data.alignment)]);

    this.container = this._make('div', containerClasses);

    const messageEl = this._make('div', [this.CSS.message], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.message,
    });

    messageEl.dataset.placeholder = this.messagePlaceholder;

    this.container.appendChild(messageEl);
    this.node.appendChild(this.container);

    return this.node;
  }

  /**
   * Create Block's settings block
   *
   * @returns {HTMLElement}
   */
  renderSettings() {
    const settingsContainer = this._make('div');

    Alert.ALERT_ALIGNMENTS.forEach((alignment) => {
      const icon = this._getAlignmentIcon(alignment);
      const settingsButton = this._make(
        'div',
        [
          this.CSS.settingsButton,
          this.CSS.alignmentButton,
          this.CSS.buttonForAlignment(alignment),
        ],
        {
          innerHTML: icon.icon,
        }
      );

      if (this.data.alignment === alignment) {
        // Highlight current type button
        settingsButton.classList.add(this.CSS.settingsButtonActive);
      }

      // Set up click handler
      settingsButton.addEventListener('click', () => {
        this._changeAlertAlignment(alignment);

        // Un-highlight previous type button
        settingsContainer
          .querySelectorAll(`.${this.CSS.alignmentButton}`)
          .forEach((button) =>
            button.classList.remove(this.CSS.settingsButtonActive)
          );

        // and highlight the clicked type button
        settingsButton.classList.add(this.CSS.settingsButtonActive);
      });

      this.api.tooltip.onHover(settingsButton, icon.title, {
        placement: 'top',
      });

      settingsContainer.appendChild(settingsButton);
    });

    Alert.ALERT_TYPES.forEach((type) => {
      const settingsButton = this._make(
          'div',
          [
            this.CSS.settingsButton,
            this.CSS.typeButton,
            this.CSS.wrapperForType(type),
          ],
          {
            innerHTML: 'A',
          }
      );

      if (this.data.type === type) {
        // Highlight current type button
        settingsButton.classList.add(this.CSS.settingsButtonActive);
      }

      // Set up click handler
      settingsButton.addEventListener('click', () => {
        this._changeAlertType(type);

        // Un-highlight previous type button
        settingsContainer
            .querySelectorAll(`.${this.CSS.typeButton}`)
            .forEach((button) =>
                button.classList.remove(this.CSS.settingsButtonActive)
            );

        // and highlight the clicked type button
        settingsButton.classList.add(this.CSS.settingsButtonActive);
      });

      settingsContainer.appendChild(settingsButton);
    });

    const blankBtn = this._make('div', [this.CSS.settingsButton, this.CSS.blankButton]);
    settingsContainer.appendChild(blankBtn);

    Alert.ALERT_DISPLAYS.forEach((display) => {
      const icon = this._getDisplayIcon(display);
      const settingsButton = this._make(
          'div',
          [
            this.CSS.settingsButton,
            this.CSS.displayButton,
            this.CSS.buttonForDisplay(display),
          ],
          {
            innerHTML: icon.icon,
          }
      );

      if (this.data.display === display) {
        // Highlight current type button
        settingsButton.classList.add(this.CSS.settingsButtonActive);
      }

      // Set up click handler
      settingsButton.addEventListener('click', () => {
        this._changeAlertDisplay(display);

        // Un-highlight previous type button
        settingsContainer
          .querySelectorAll(`.${this.CSS.displayButton}`)
          .forEach((button) =>
              button.classList.remove(this.CSS.settingsButtonActive)
          );

        // and highlight the clicked type button
        settingsButton.classList.add(this.CSS.settingsButtonActive);
      });

      this.api.tooltip.onHover(settingsButton, icon.title, {
        placement: 'top',
      });

      settingsContainer.appendChild(settingsButton);
    });

    return settingsContainer;
  }

  /**
   * icon
   *
   * @public
   * @param {string} alignment
   * @returns {array}
   */
  _getAlignmentIcon(alignment) {
    const alignmentArray = {
      'left': {
        icon: '<svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20"><path d="m54 8h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 52h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m10 23h28c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/><path d="m54 30h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m10 45h28c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/></svg>',
        title: '左寄せ'
      },
      'center': {
        icon: '<svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20"><path d="m54 8h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 52h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m46 23c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/><path d="m54 30h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m46 45c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/></svg>',
        title: '中央寄せ'
      },
      'right': {
        icon: '<svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20"><path d="m54 8h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 52h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 19h-28c-1.104 0-2 .896-2 2s.896 2 2 2h28c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 30h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 41h-28c-1.104 0-2 .896-2 2s.896 2 2 2h28c1.104 0 2-.896 2-2s-.896-2-2-2z"/></svg>',
        title: '右寄せ'
      }
    };
    return alignmentArray[alignment];
  }

  /**
   * icon
   *
   * @public
   * @param {string} display
   * @returns {array}
   */
  _getDisplayIcon(display) {
    const displayArray = {
      'block': {
        icon: '全幅',
        title: '幅を100%にする'
      },
      'inline-block': {
        icon: '字幅',
        title: '幅を文字に合わせる'
      }
    };
    return displayArray[display];
  }

  /**
   * Helper for changing style of Alert block with the selected Alert alignment
   *
   * @param {string} newAlignment - new Alert alignment to be applied to the block
   * @private
   */
  _changeAlertAlignment(newAlignment) {
    // Save new type
    this.data.alignment = newAlignment;

    Alert.ALERT_ALIGNMENTS.forEach((alignment) => {
      const alertClass = this.CSS.wrapperForAlignment(alignment);

      // Remove the old Alert type class
      this.node.classList.remove(alertClass);

      if (newAlignment === alignment) {
        // Add an Alert class for the selected Alert type
        this.node.classList.add(alertClass);
      }
    });
  }

  /**
   * Helper for changing style of Alert block with the selected Alert type
   *
   * @param {string} newType - new Alert type to be applied to the block
   * @private
   */
  _changeAlertType(newType) {
    // Save new type
    this.data.type = newType;

    Alert.ALERT_TYPES.forEach((type) => {
      const alertClass = this.CSS.wrapperForType(type);

      // Remove the old Alert type class
      this.container.classList.remove(alertClass);

      if (newType === type) {
        // Add an Alert class for the selected Alert type
        this.container.classList.add(alertClass);
      }
    });
  }

  /**
   * Helper for changing style of Alert block with the selected Alert display
   *
   * @param {string} newDisplay - new Alert display to be applied to the block
   * @private
   */
  _changeAlertDisplay(newDisplay) {
    console.log(`_changeAlertDisplay: ${newDisplay}`);
    // Save new type
    this.data.display = newDisplay;

    Alert.ALERT_DISPLAYS.forEach((display) => {
      console.log(`newDisplay: ${newDisplay} / display: ${display}`);
      const alertClass = this.CSS.wrapperForDisplay(display);
      console.log(alertClass);

      // Remove the old Alert type class
      this.container.classList.remove(alertClass);

      if (newDisplay === display) {
        // Add an Alert class for the selected Alert type
        this.container.classList.add(alertClass);
      }
    });
  }

  /**
   * Extract Alert data from Alert Tool element
   *
   * @param {HTMLDivElement} alertElement - element to save
   * @returns {AlertData}
   */
  save(alertElement) {
    const messageEl = alertElement.querySelector(`.${this.CSS.message}`);

    return { ...this.data, message: messageEl.innerHTML };
  }

  /**
   * Helper for making Elements with attributes
   *
   * @param  {string} tagName           - new Element tag name
   * @param  {array|string} classNames  - list or name of CSS classname(s)
   * @param  {Object} attributes        - any attributes
   * @returns {Element}
   * @private
   */
  _make(tagName, classNames = null, attributes = {}) {
    let el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (let attrName in attributes) {
      el[attrName] = attributes[attrName];
    }

    return el;
  }

  /**
   * Fill Alert's message with the pasted content
   *
   * @param {PasteEvent} event - event with pasted content
   */
  onPaste(event) {
    const { data } = event.detail;

    this.data = {
      type: this.defaultType,
      message: data.innerHTML || '',
    };
  }

  /**
   * Allow Alert to be converted to/from other blocks
   */
  static get conversionConfig() {
    return {
      // export Alert's message for other blocks
      export: (data) => data.message,
      // fill Alert's message from other block's export string
      import: (string) => {
        return {
          message: string,
          type: this.DEFAULT_TYPE,
        };
      },
    };
  }

  /**
   * Sanitizer config for Alert Tool saved data
   * @returns {Object}
   */
  static get sanitize() {
    return {
      type: false,
      message: true,
    };
  }
}
