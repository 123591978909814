/**
 * @file 商品モーダルの内容書き換え
 */
'use strict';

/**
 * @classname ExchangeModal
 * @classdesc 商品モーダルの内容書き換え
 */
export default class ExchangeModal {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('ExchangeModal.constructor');
		this._$e = $e;
		this._$prize_id = this._$e.attr('prize-id');
		// モーダル内の要素取得
		this._$modal = $('#modal-item-detail');
		this._$modalTitle = this._$modal.find('.modal-title');
		this._$modalImg = this._$modal.find('.image');
		this._$modalDirect = this._$modal.find('.anchor-point-modal');
		this._$modalText = this._$modal.find('.text');
		// 書き換える内容取得
		this._$title = this._$e.closest('.list-item').find('.text-product-text').data('product-title');
		this._$text = this._$e.closest('.list-item').find('.text-product-text').data('product-detail');
		this._$image = this._$e.closest('.list-item').find('.image').attr('src');
		this._$direct = this._$e.closest('.list-item').find('.anchor-point');
		$e.on('click', () => this.onClick());
	};
 

	/**
	 * clickのコールバック
	 *
	 * @memberof ExchangeModal
	 * @return {undefined}
	 */
	onClick() {
		console.log('ExchangeModal.onClick');
		console.log(this._$text)
		this._$modalTitle.text(this._$title);
		this._$modalImg.attr('src',this._$image);
		this._$modalText.html(this._$text);
		if(this._$direct.hasClass('direct')) {
			this._$modalDirect.addClass('direct')
		}else {
			this._$modalDirect.removeClass('direct')
		}
		console.log('Modal.onClick');
	};

}