/**
 * @file 組織を開く
 */
'use strict';

/**
 * @classname ToggleList
 * @classdesc 組織を開く
 */
export default class ToggleList {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('ToggleList.constructor');
		this._$e = $e;
		$e.on('click', () => this.onClick());
		this._$secondList = $e.closest('.block-organization').next('.list-second');
		console.log(this._$secondList);
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof ToggleList
	 * @return {undefined}
	 */
	onClick() {
		console.log('ToggleList.onClick');
		this._$e.hasClass('open')? this.close() : this.open();
	};


	/**
	 * 開く
	 *
	 * @memberof ToggleList
	 * @return {undefined}
	 */
	open() {
		console.log('ToggleList.open');
		this._$e.addClass('open').find('i').removeClass('fa-chevron-down').addClass('fa-chevron-up');
		this._$secondList.slideDown();
	};


	/**
	 * 閉じる
	 *
	 * @memberof ToggleList
	 * @return {undefined}
	 */
	close() {
		console.log('ToggleList.close');
		this._$e.removeClass('open').find('i').removeClass('fa-chevron-up').addClass('fa-chevron-down');
		this._$secondList.slideUp();
	};
}
