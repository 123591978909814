/**
 * @file ファイルドロップ
 */
'use strict';

import ApplyClass from '../../Utils/ApplyClass';
import Common from '../../Utils/Common';
import Validate from './Validate';
import DeletePhoto  from './DeletePhoto';

/**
 * @classname UploadPhoto
 * @classdesc ファイルドロップ
 */
export default class UploadPhoto {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controllerオブジェクト
	 */
	constructor($e, controller) {
		console.log('UploadPhoto.constructor');
		this._$e = $e;
		this._controller = controller;
		this._userId = parseInt($('#user_id').val());
		this._$blockUploadPhoto = $e.closest('.block-file-upload');
		this._$inputFile = this._$blockUploadPhoto.find('input[type=file]');
		this._$inputFileWrap = this._$blockUploadPhoto.find('.block-file-input');
		this._inputFile = '<input type="file" accept="image/*" style="display:none;">';
		this._orientation = 1;
		this._$photoArea = $e.closest('.form-group').find('.block-photo-wrap');
		this._upload = false;
		this._$e.on('dragover', e => this.onDragover(e));
		this._$e.on('dragleave', () => this.onDragleave());
		this._$e.on('drop', e => this.onDrop(e));
		this._$e.on('click', e => this.onClick(e));
		this._$inputFile.on('change', e => this.onChange(e));
		this._controller.on('uploadPhotoComplete', () => this.onUploadPhotoComplete());
		this._controller.on('deletePhotoComplete', () => this.onDeletePhotoComplete());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof UploadPhoto
	 * @return {undefined}
	 */
	onClick(e){
		console.log('UploadPhoto.onClick');
		e.preventDefault();
		e.stopPropagation();
		console.log('inputFile:');
		this._$inputFile.click();
	}
	/**
	 * ドラッグオーバーのコールバック
	 *
	 * @memberof UploadPhoto
	 * @param {object} e - イベント
	 * @return {undefined}
	 */
	onDragover(e) {
		console.log('UploadPhoto.onDragover');
		e = e.originalEvent;
		e.dataTransfer.dropEffect = 'copy';
		e.preventDefault();
		this._$e.addClass('dragover');
	};


	/**
	 * ドラッグリーブのコールバック
	 *
	 * @memberof UploadPhoto
	 * @return {undefined}
	 */
	onDragleave() {
		this._$e.removeClass('dragover');
	};


	/**
	 * ドロップのコールバック
	 *
	 * @memberof UploadPhoto
	 * @return {undefined}
	 */
	onDrop(e) {
		console.log('UploadPhoto.onDrop');
		e = e.originalEvent;
		e.preventDefault();
		this._$e.removeClass('dragover');
		this._files = e.dataTransfer.files;
		if(this._files.length > 1) {
			Common.showErrorModal('画像は1枚ずつあげてください。');
			return;
		}
		const file = this._files[0];
		const maxMB = window.const.FILE.MAX_MB;
		const maxSize = maxMB * 1024 * 1024;
		if (file.size > maxSize) {
			Common.showErrorModal(`画像サイズは${maxMB}MB以下の必要があります。`);
			return;
		}
		this._upload = true;
		this._controller.uploadPhoto(file, this._userId);
	};


	/**
	 * ファイル変更のコールバック
	 *
	 * @memberof UploadPhoto
	 * @return {undefined}
	 */
	onChange(e) {
		console.log('UploadPhoto.onChange');
		this._$blockUploadPhoto.addClass('uploading');
		const file = e.target.files[0];
		const maxMB = window.const.FILE.MAX_MB;
		const maxSize = maxMB * 1024 * 1024;
		if (file.size > maxSize) {
			Common.showErrorModal(`画像サイズは${maxMB}MB以下の必要があります。`);
			this.onDeletePhotoComplete();
			return;
		}
		this._upload = true;
		const reader = new FileReader();
		reader.onload = this._controller.uploadPhoto(file, this._userId);
	};


	/**
	 * 画像の表示
	 *
	 * @memberof UploadPhoto
	 * @return {undefined}
	 */
	onUploadPhotoComplete() {
		if(!this._upload) return;
		console.log('UploadPhoto.onUploadPhotoComplete');
		this._$blockUploadPhoto.removeClass('uploading');
		const imageData = this._controller._imageData;
		console.log(imageData);
		let img = `<div class="image-box image-box-photo"><img class="image" src="${imageData.url}" alt=""><button class="btn btn-delete-photo" type="button" data-photo-id="${imageData.photo_id}">×</button></div>`;
		this._$photoArea.html(img);
		this._$e.closest('.form-group').find('.photo_id').val(imageData.url);
		this._$inputFileWrap.html(this._inputFile);
		this._$inputFile = this._$blockUploadPhoto.find('input');
		this._$inputFile.on('change', e => this.onChange(e));
		ApplyClass.apply(Validate, this._$inputFile, [this._$inputFile, this._controller]);
		this._$inputFile.closest('.form-group').find('.validate').removeClass('is-invalid');
		this._$inputFile.closest('.form-group').find('.error-tip').removeClass('show').find('.error-tip-inner').html('');
		if(this._$e.hasClass('file-upload-board')){
			$('.block-thumb-rotate').slideDown();
		}
		const $deleteImage = $('.btn-delete-photo');
		$deleteImage.off();
		$deleteImage.each((idx, e) => {
			const $e = $(e);
			ApplyClass.apply(DeletePhoto, $e, [$e, this._controller])
		});
		this._upload = false;
	};


	/**
	 * 画像の削除
	 *
	 * @memberof UploadPhoto
	 * @return {undefined}
	 */
	onDeletePhotoComplete() {
		console.log('UploadPhoto.onDeletePhotoComplete');
		this._$inputFileWrap.html(this._inputFile);
		this._$inputFile = this._$blockUploadPhoto.find('input[type=file]');
		this._$inputFile.on('change', e => this.onChange(e));
	};
}
