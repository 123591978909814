/**
 * @file 組織開閉
 */
'use strict';

import Cookies from "js-cookie";

/**
 * @classname OrganizationSwitch
 * @classdesc 組織開閉
 */
export default class OrganizationSwitch {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('OrganizationSwitch.constructor');
		this._$e = $e;
		this._$block = $e.next('.block-user-organization');
		this._$target = this._$block.find('.block-item');
		this._id = this._$block.attr('data-id');
		this._$parent = $e.closest('.list-item');
		$e.on('click', () => this.onClick());
		this.init();
	};


	/**
	 * 初期オープン
	 *
	 * @memberof OrganizationSwitch
	 * @return {undefined}
	 */
	init() {
		console.log('OrganizationSwitch.init');
		const organizations = Cookies.get('organizations') || '';
		const opened = organizations.split(',');
		for(let i = 0; i < opened.length; i++){
			if(this._id === opened[i]){
				this._$e.addClass('open').removeClass('unopen');
				setTimeout(()=>{
					this._$target.show();
				}, 200);
				this._$block.removeClass('unopen').addClass('open');
				if(!this._$parent.hasClass('no-dept')){
					this._$parent.find('.list-user-organization-inner').show();
				}
			}
		}
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof OrganizationSwitch
	 * @return {undefined}
	 */
	onClick() {
		console.log('OrganizationSwitch.onClick');
		if(this._$e.hasClass('open')){
			this._$e.removeClass('open').addClass('unopen');
			this._$target.slideUp(200);
			this._$block.removeClass('open').addClass('unopen');
			if(!this._$parent.hasClass('no-dept')){
				this._$parent.find('.list-user-organization-inner').hide();
			}
		}else{
			this._$e.addClass('open').removeClass('unopen');
			setTimeout(()=>{
				this._$target.slideDown();
			}, 200);
			this._$block.removeClass('unopen').addClass('open');
			if(!this._$parent.hasClass('no-dept')){
				this._$parent.find('.list-user-organization-inner').slideDown();
			}
		}
		this.checkOpen();
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof OrganizationSwitch
	 * @return {undefined}
	 */
	checkOpen() {
		console.log('OrganizationSwitch.checkOpen');
		const opened = $('.block-user-organization').map((idx, e) =>{
			const $e =  $(e);
			if($e.hasClass('open')) return $e.attr('data-id');
		}).get().join(',');
		console.log(opened);
		Cookies.set('organizations', opened, { expires: 1/480 });
	};
}
