/**
 * @file 投稿ボタン
 */
'use strict';
import Common from '../../Utils/Common';

/**
 * @classname BtnPost
 * @classdesc 投稿ボタン
 */
export default class BtnPost {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controller オブジェクト
	 */
	constructor($e, controller) {
		console.log('BtnPost.constructor');
		this._$e = $e;
		this._controller = controller;
		this._$modal = $('#modal-submit');
		$e.on('click', () => this.onClick());
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof BtnPost
	 * @return {undefined}
	 */
	onClick() {
		console.log('BtnPost.onClick');
		this._$modal.find('.text').hide();
		const now = new Date();
		const postDate = $('[name="public_start_at"]').val();
		if(postDate && postDate !== ''){
			const tmp = postDate.split(' ');
			const tmp1 = tmp[0].split('-');
			const tmp2 = tmp[1].split(':');
			const post = new Date(tmp1[0], tmp1[1] - 1, tmp1[2], tmp2[0], tmp2[1], 0);
			if(post > now) {
				$('.text-feature-post').show();
			}else{
				$('.text-immediate-post').show();
			}
			this._$modal.addClass('show').show();
		}else{
			$('#modal-submit .btn-primary').trigger('click');
		}
	};
}
