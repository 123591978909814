/**
 * 
 * @file 社員ガチャを引く
 */
'use strict';


/**
 * @classname PreventMash
 * 
 */
export default class PreventMash {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		this._el = $('.run-gacha')
		$e.on('click', (e) => this.onClick(e));
	};
	/**
	 * 連打防止
	 * @return {undefined}
	 */
	 onClick(e) {
		 console.log(this._el)
		e.preventDefault();
		if(!this._el.hasClass('secondTime')) {
			console.log(this._el.hasClass)
			console.log(this._el.attr('class'))
			this._el.addClass('secondTime')
			window.location.href = '/user/gacha/'
		}else {
			console.log("out")
			return false
		}
	};

}