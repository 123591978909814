"use strict";

// utility class
import ApplyClass from "../Utils/ApplyClass";
import Common from "../Utils/Common";
import Basic from "../Controller/Basic";

import SmoothScroll from "../View/Basic/SmoothScroll";
import ToggleList from "../View/Basic/ToggleList";
import ListSort from "../View/Basic/ListSort";
import Tab from "../View/Basic/Tab";
import Modal from "../View/Basic/Modal";
import ModalClose from "../View/Basic/ModalClose";

import TableSort from "../View/Basic/TableSort";
import Pagination from "../View/Basic/Pagination";
import TableSearch from "../View/Basic/TableSearch";
import ClearSearchCondition from "../View/Basic/ClearSearchCondition";

import Stars from "../View/Basic/Stars";
import OrganizationSwitch from "../View/Basic/OrganizationSwitch";
import OrganizationText from "../View/Basic/OrganizationText";
import SearchResult from "../View/Basic/SearchResult";
import OrganizationSearch from "../View/Basic/OrganizationSearch";
import OrganizationUserList from "../View/Basic/OrganizationUserList";
import CreateStars from "../View/Basic/CreateStars";
import ReturnStars from "../View/Basic/ReturnStars";
import InfoMore from "../View/Basic/InfoMore";
import SubPhoto from "../View/Basic/SubPhoto";
import TakeMask from "../View/Basic/TakeMask";
import EnterSubmit from "../View/Basic/EnterSubmit";
import PreventMash from "../View/Basic/PreventMash";
import ExchangeModal from "../View/Basic/ExchangeModal";
import DetailModal from "../View/Basic/DetailModal";
import CompleteModal from "../View/Basic/CompleteModal";
import SpMenu from "../View/Basic/SpMenu";
import Accordion from "../View/Basic/Accordion";
import CheckBoardNew from "../View/Basic/CheckBoardNew";
import CloseAlert from "../View/Basic/CloseAlert";

import Gacha from "../View/Basic/Gacha";
import BtnSpSearch from "../View/Basic/SpSearch";
import AnchorSpFilter from "../View/Basic/AnchorSpFilter";
import ArticleReaction from "../View/Basic/ArticleReaction";
import AdjustGachaHistoryTab from "../View/Basic/AdjustGachaHistoryTab";
import LayeredAccordion from "../View/Basic/LayeredAccordion";
import SeeMore from "../View/Basic/SeeMore";
import Lightbox from "../View/Basic/Lightbox";
import ImageNoDownload from "../View/Basic/ImageNoDownload";

$(() => {
	const BasicController = new Basic();
	// デバイス、ブラウザ判定
	Common.addHtmlClass();
	objectFitImages();
	//スムーススクロール
	$('a[href^="#"]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(SmoothScroll, $e, [$e, BasicController]);
	});
	// モーダル
	$("[data-modal=true]").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Modal, $e, [$e, BasicController]);
	});
	// モーダル閉じる
	$(".modal [data-dismiss=modal]").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ModalClose, $e, [$e, BasicController]);
	});
	// Alert閉じる
	$(".alert [data-dismiss=alert]").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(CloseAlert, $e, [$e, BasicController]);
	});
	//リスト開閉
	$(".anchor-toggle-list").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ToggleList, $e, [$e, BasicController]);
	});
	//リスト開閉
	$(".list-organization").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ListSort, $e, [$e, BasicController]);
	});

	// テーブルソート
	$(".table-sort").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(TableSort, $e, [$e, BasicController]);
	});
	// ページネーション
	$(".page-link").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Pagination, $e, [$e, BasicController]);
	});
	// テーブル検索
	$(".btn-table-search").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(TableSearch, $e, [$e, BasicController]);
	});
	// 検索条件クリア
	$(".anchor-clear-search").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ClearSearchCondition, $e, [$e, BasicController]);
	});
	// STARS select
	$(".stars-wrap").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Stars, $e, [$e, BasicController]);
	});
	// STARS復帰
	$("#stars").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ReturnStars, $e, [$e, BasicController]);
	});
	// Tab
	$(".nav-link").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Tab, $e, [$e, BasicController]);
	});
	// 組織開閉
	$(".anchor-organization-switch").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(OrganizationSwitch, $e, [$e, BasicController]);
	});
	// 組織説明文開閉
	$(".anchor-organization-toggle").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(OrganizationText, $e, [$e, BasicController]);
	});
	// 組織説明文開閉
	$(".anchor-search-result").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(SearchResult, $e, [$e, BasicController]);
	});
	// 組織検索
	$(".btn-organization-search").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(OrganizationSearch, $e, [$e, BasicController]);
	});
	// STARS作成
	$("#create-stars").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(CreateStars, $e, [$e, BasicController]);
	});
	// お知らせもっと見る
	$(".anchor-info-more").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(InfoMore, $e, [$e, BasicController]);
	});
	const $pageGacha = $(".page-gacha");
	if ($("body").hasClass("page-gacha")) {
		ApplyClass.apply(Gacha, $pageGacha, [$pageGacha, BasicController]);
	}
	// 追加写真
	$(".anchor-detail-sub-photo").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(SubPhoto, $e, [$e, BasicController]);
	});
	// マスク着用
	$(".anchor-mask").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(TakeMask, $e, [$e, BasicController]);
	});
	// Enterでsubmit
	$(".organization-search").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(EnterSubmit, $e, [$e, BasicController]);
	});
	//ボタンの連打防止
	$(".run-gacha").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(PreventMash, $e, [$e, BasicController]);
	});
	// 応募申し込みの商品モーダルの内容書き換え
	$(".btn-exchange-modal").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ExchangeModal, $e, [$e, BasicController]);
	});
	// 商品詳細モーダルの内容書き換え
	$(".anchor-detail-modal").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(DetailModal, $e, [$e, BasicController]);
	});
	// 画面リロードする(閉じる)ボタン
	$(".btn-complete-modal").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(CompleteModal, $e, [$e, BasicController]);
	});
	// SP MENU
	$(".btn-sp-menu").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(SpMenu, $e, [$e, BasicController]);
	});
	// Accordion
	$('[data-accordion-type="wrap"]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Accordion, $e, [$e, BasicController]);
	});
	// LayeredAccordion
	$('[data-accordion-type="layered-wrap"]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(LayeredAccordion, $e, [$e, BasicController]);
	});
	// 投稿日時 NEW
	$("[data-post-date]").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(CheckBoardNew, $e, [$e, BasicController]);
	});
	// SPヘッダーの社員名検索ボタン
	$(".btn-sp-search").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(BtnSpSearch, $e, [$e, BasicController]);
	});
	// プロフィール一覧フィルター
	$(".anchor-sp-filter").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(AnchorSpFilter, $e, [$e, BasicController]);
	});
	// 読んだよ！ボタン
	$(".btn-article-reaction").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ArticleReaction, $e, [$e, BasicController]);
	});
	// ダブルチャンス当選履歴のページネーションをクリックしたときのタブ復帰
	$(".page-mypage-gacha-history").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(AdjustGachaHistoryTab, $e, [$e, BasicController]);
	});
	// もっと見るのテキスト切り替え
	$(".see-more").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(SeeMore, $e, [$e, BasicController]);
	});
	// lightbox
	$("#codex-editor").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Lightbox, $e, [$e, BasicController]);
	});
	$("body.page-mypage-board").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Lightbox, $e, [$e, BasicController]);
	});
	$("body.page-idea-board").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Lightbox, $e, [$e, BasicController]);
	});
	// もっと見るのテキスト切り替え
	$(".list-user-organization").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(OrganizationUserList, $e, [$e, BasicController]);
	});
	// 画像ダウンロード禁止
	$(".image").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ImageNoDownload, $e, [$e, BasicController]);
	});
});

//object-fit polyfill
/*! npm.im/object-fit-images 3.2.4 */
var objectFitImages = (function () {
	"use strict";

	var OFI = "bfred-it:object-fit-images";
	var propRegex = /(object-fit|object-position)\s*:\s*([-.\w\s%]+)/g;
	var testImg =
		typeof Image === "undefined"
			? { style: { "object-position": 1 } }
			: new Image();
	var supportsObjectFit = "object-fit" in testImg.style;
	var supportsObjectPosition = "object-position" in testImg.style;
	var supportsOFI = "background-size" in testImg.style;
	var supportsCurrentSrc = typeof testImg.currentSrc === "string";
	var nativeGetAttribute = testImg.getAttribute;
	var nativeSetAttribute = testImg.setAttribute;
	var autoModeEnabled = false;

	function createPlaceholder(w, h) {
		return (
			"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='" +
			w +
			"' height='" +
			h +
			"'%3E%3C/svg%3E"
		);
	}

	function polyfillCurrentSrc(el) {
		if (el.srcset && !supportsCurrentSrc && window.picturefill) {
			var pf = window.picturefill._;
			// parse srcset with picturefill where currentSrc isn't available
			if (!el[pf.ns] || !el[pf.ns].evaled) {
				// force synchronous srcset parsing
				pf.fillImg(el, { reselect: true });
			}

			if (!el[pf.ns].curSrc) {
				// force picturefill to parse srcset
				el[pf.ns].supported = false;
				pf.fillImg(el, { reselect: true });
			}

			// retrieve parsed currentSrc, if any
			el.currentSrc = el[pf.ns].curSrc || el.src;
		}
	}

	function getStyle(el) {
		var style = getComputedStyle(el).fontFamily;
		var parsed;
		var props = {};
		while ((parsed = propRegex.exec(style)) !== null) {
			props[parsed[1]] = parsed[2];
		}
		return props;
	}

	function setPlaceholder(img, width, height) {
		// Default: fill width, no height
		var placeholder = createPlaceholder(width || 1, height || 0);

		// Only set placeholder if it's different
		if (nativeGetAttribute.call(img, "src") !== placeholder) {
			nativeSetAttribute.call(img, "src", placeholder);
		}
	}

	function onImageReady(img, callback) {
		// naturalWidth is only available when the image headers are loaded,
		// this loop will poll it every 100ms.
		if (img.naturalWidth) {
			callback(img);
		} else {
			setTimeout(onImageReady, 100, img, callback);
		}
	}

	function fixOne(el) {
		var style = getStyle(el);
		var ofi = el[OFI];
		style["object-fit"] = style["object-fit"] || "fill"; // default value

		// Avoid running where unnecessary, unless OFI had already done its deed
		if (!ofi.img) {
			// fill is the default behavior so no action is necessary
			if (style["object-fit"] === "fill") {
				return;
			}

			// Where object-fit is supported and object-position isn't (Safari < 10)
			if (
				!ofi.skipTest && // unless user wants to apply regardless of browser support
				supportsObjectFit && // if browser already supports object-fit
				!style["object-position"] // unless object-position is used
			) {
				return;
			}
		}

		// keep a clone in memory while resetting the original to a blank
		if (!ofi.img) {
			ofi.img = new Image(el.width, el.height);
			ofi.img.srcset =
				nativeGetAttribute.call(el, "data-ofi-srcset") || el.srcset;
			ofi.img.src = nativeGetAttribute.call(el, "data-ofi-src") || el.src;

			// preserve for any future cloneNode calls
			// https://github.com/bfred-it/object-fit-images/issues/53
			nativeSetAttribute.call(el, "data-ofi-src", el.src);
			if (el.srcset) {
				nativeSetAttribute.call(el, "data-ofi-srcset", el.srcset);
			}

			setPlaceholder(
				el,
				el.naturalWidth || el.width,
				el.naturalHeight || el.height
			);

			// remove srcset because it overrides src
			if (el.srcset) {
				el.srcset = "";
			}
			try {
				keepSrcUsable(el);
			} catch (err) {
				if (window.console) {
					console.warn("https://bit.ly/ofi-old-browser");
				}
			}
		}

		polyfillCurrentSrc(ofi.img);

		el.style.backgroundImage =
			'url("' + (ofi.img.currentSrc || ofi.img.src).replace(/"/g, '\\"') + '")';
		el.style.backgroundPosition = style["object-position"] || "center";
		el.style.backgroundRepeat = "no-repeat";
		el.style.backgroundOrigin = "content-box";

		if (/scale-down/.test(style["object-fit"])) {
			onImageReady(ofi.img, function () {
				if (
					ofi.img.naturalWidth > el.width ||
					ofi.img.naturalHeight > el.height
				) {
					el.style.backgroundSize = "contain";
				} else {
					el.style.backgroundSize = "auto";
				}
			});
		} else {
			el.style.backgroundSize = style["object-fit"]
				.replace("none", "auto")
				.replace("fill", "100% 100%");
		}

		onImageReady(ofi.img, function (img) {
			setPlaceholder(el, img.naturalWidth, img.naturalHeight);
		});
	}

	function keepSrcUsable(el) {
		var descriptors = {
			get: function get(prop) {
				return el[OFI].img[prop ? prop : "src"];
			},
			set: function set(value, prop) {
				el[OFI].img[prop ? prop : "src"] = value;
				nativeSetAttribute.call(el, "data-ofi-" + prop, value); // preserve for any future cloneNode
				fixOne(el);
				return value;
			},
		};
		Object.defineProperty(el, "src", descriptors);
		Object.defineProperty(el, "currentSrc", {
			get: function () {
				return descriptors.get("currentSrc");
			},
		});
		Object.defineProperty(el, "srcset", {
			get: function () {
				return descriptors.get("srcset");
			},
			set: function (ss) {
				return descriptors.set(ss, "srcset");
			},
		});
	}

	function hijackAttributes() {
		function getOfiImageMaybe(el, name) {
			return el[OFI] && el[OFI].img && (name === "src" || name === "srcset")
				? el[OFI].img
				: el;
		}
		if (!supportsObjectPosition) {
			HTMLImageElement.prototype.getAttribute = function (name) {
				return nativeGetAttribute.call(getOfiImageMaybe(this, name), name);
			};

			HTMLImageElement.prototype.setAttribute = function (name, value) {
				return nativeSetAttribute.call(
					getOfiImageMaybe(this, name),
					name,
					String(value)
				);
			};
		}
	}

	function fix(imgs, opts) {
		var startAutoMode = !autoModeEnabled && !imgs;
		opts = opts || {};
		imgs = imgs || "img";

		if ((supportsObjectPosition && !opts.skipTest) || !supportsOFI) {
			return false;
		}

		// use imgs as a selector or just select all images
		if (imgs === "img") {
			imgs = document.getElementsByTagName("img");
		} else if (typeof imgs === "string") {
			imgs = document.querySelectorAll(imgs);
		} else if (!("length" in imgs)) {
			imgs = [imgs];
		}

		// apply fix to all
		for (var i = 0; i < imgs.length; i++) {
			imgs[i][OFI] = imgs[i][OFI] || {
				skipTest: opts.skipTest,
			};
			fixOne(imgs[i]);
		}

		if (startAutoMode) {
			document.body.addEventListener(
				"load",
				function (e) {
					if (e.target.tagName === "IMG") {
						fix(e.target, {
							skipTest: opts.skipTest,
						});
					}
				},
				true
			);
			autoModeEnabled = true;
			imgs = "img"; // reset to a generic selector for watchMQ
		}

		// if requested, watch media queries for object-fit change
		if (opts.watchMQ) {
			window.addEventListener(
				"resize",
				fix.bind(null, imgs, {
					skipTest: opts.skipTest,
				})
			);
		}
	}

	fix.supportsObjectFit = supportsObjectFit;
	fix.supportsObjectPosition = supportsObjectPosition;

	hijackAttributes();

	return fix;
})();

// array includes polyfill
if (!Array.prototype.includes) {
	Object.defineProperty(Array.prototype, "includes", {
		value: function (searchElement, fromIndex) {
			if (this == null) {
				throw new TypeError('"this" is null or not defined');
			}

			var o = Object(this);
			var len = o.length >>> 0;

			if (len === 0) {
				return false;
			}

			var n = fromIndex | 0;
			var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

			while (k < len) {
				if (o[k] === searchElement) {
					return true;
				}
				k++;
			}

			return false;
		},
	});
}
