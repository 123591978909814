/**
 * @file 掲示板画像回転
 */
'use strict';

/**
 * @classname ImageRotate
 * @classdesc 掲示板画像回転
 */
export default class ImageRotate {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('ImageRotate.constructor');
		const timer = setInterval(() => {
			const width = $e.width();
			const height = $e.height();
			console.log(width, height);
			if(width !== 0) {
				const scale = width / height;
				const wrapperHeight = Math.ceil(width * scale);
				const margin = (height - wrapperHeight) / -2;
				let style = $e.attr('style');
				style = `${style}margin-top:${margin}px;margin-bottom:${margin}px;`;
				$e.attr('style', style);
				clearInterval(timer);
			}
		},100);
	};
}