/**
 * @file 追加写真
 */
'use strict';

/**
 * @classname SubPhoto
 * @classdesc 追加写真
 */
export default class SubPhoto {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('SubPhoto.constructor');
		this._$e = $e;
		this._$modal = $('#modal-sub-photo');
		$e.on('click', () => this.onClick());
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof SubPhoto
	 * @return {undefined}
	 */
	onClick() {
		console.log('SubPhoto.onClick');
		this._$modal.addClass('show').show();
	};
}
