/**
 * @file 文字カウンター
 */
'use strict';

/**
 * @classname ChangeTeam
 * @classdesc 文字カウンター
 */
export default class ChangeTeam {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('ChangeTeam.constructor');
		this._$e = $e;
		$e.on('change', () => this.onChange());
	};


	/**
	 * changeのコールバック
	 *
	 * @memberof ChangeTeam
	 * @return {undefined}
	 */
	onChange() {
		console.log('ChangeTeam.onChange');
		const val = this._$e.val();
		let options = '';
		for(let i = 0; i < baseTeam.length; i++){
			const team = baseTeam[i];
			if(team.parent_id === parseInt(val)){
				options += `<option value="${team.value}">${team.name}</option>`;
			}
		}
		$('.team_id').html(options).trigger('change');
	};
}
