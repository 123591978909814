/**
 * @file SpMenu
 */
'use strict';

/**
 * @classname SpMenu
 * @classdesc SpMenu
 */
export default class SpMenu {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('.btn-sp-menu')
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`);
		this._$e = $e;
		this._$body = $('body');
		this._$drawer = $('.drawer-sp-menu');
		this._$drawerContent = this._$drawer.find('.drawer-content');
		$e.on('click', () => this.onClick());
		this._$drawer.find('.drawer-background').on('click', () => this.close());
		window.onresize = () => (window.innerWidth >= 992) && this.close();
		this._$drawerContent.on('touchstart', e => this.onTouchstart(e));
		this._$drawerContent.on('touchmove', e => this.onTouchmove(e));
		this._$drawerContent.on('touchend', () => this.onTouchend());
	}


	/**
	 * クリックのコールバック
	 *
	 * @memberof SpMenu
	 * @return {undefined}
	 */
	onClick() {
		console.log(`${this.constructor.name}.onClick`);
		this._$drawer.toggleClass('is-open');
		this._$body.toggleClass('is-overflow-hidden');
	}


	/**
	 * SpMenu を閉じる
	 *
	 * @memberof SpMenu
	 * @return {undefined}
	 */
	close() {
		console.log(`${this.constructor.name}.close`);
		this._$drawer.removeClass('is-open');
		this._$body.removeClass('is-overflow-hidden');
	}


	/**
	 * onTouchstart
	 *
	 * @memberof SpMenu
	 * @return {undefined}
	 */
	onTouchstart(e) {
		console.log(`${this.constructor.name}.onTouchstart`);
		[this._touchstartX, this._endX] = [e.touches[0].pageX, e.touches[0].pageX];
	}


	/**
	 * onTouchmove
	 *
	 * @memberof SpMenu
	 * @return {undefined}
	 */
	onTouchmove(e) {
		console.log(`${this.constructor.name}.onTouchmove`);
		this._endX = e.touches[0].pageX;
	}


	/**
	 * onTouchend
	 *
	 * @memberof SpMenu
	 * @return {undefined}
	 */
	onTouchend() {
		console.log(`${this.constructor.name}.onTouchend`);
		console.log(`swipelog: {start: ${Math.round(this._touchstartX)}, end: ${Math.round(this._endX)}}`);
		if(this._endX < this._touchstartX) this.close();
		[this._touchstartX, this._endX] = [0, 0];
	}
}
