/**
 * @file 削除するダブルチャンスのIDをセットする
 */
'use strict';

/**
 * @classname DeleteDoubleChance
 * @classdesc 削除するダブルチャンスのIDをセットする
 */
export default class DeleteDoubleChance {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`);
		this._id = $e.attr('data-double-chance-id');
		$e.on('click', () => this.onClick());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof DeleteDoubleChance
	 * @return {undefined}
	 */
	onClick() {
		console.log(`${this.constructor.name}.onClick`);
		$('#double_chance_id').val(this._id);
	};
}
