/**
 * @file ダブルチャンス当選履歴のページネーションをクリックしたときのタブ復帰
 */
'use strict';

/**
 * @classname AdjustGachaHistoryTab
 * @classdesc AdjustGachaHistoryTab
 */
export default class AdjustGachaHistoryTab {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('.page-mypage-gacha-history')
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`);
		if(location.search.match(/page=/)){
			$('#win-tab').trigger('click');
			$('#win-tab').closest('.nav-link').addClass('active');
		} else {
			$('#get-tab').closest('.nav-link').addClass('active');
		}
		$('.tab-content').fadeIn(300);
	}
}
