/**
 * @file 掲示板新規投稿・掲示板投稿編集 ピン留めのオン/オフ切り替え
 */
'use strict';

/**
 * @classname ChangePinned
 * @classdesc 掲示板新規投稿・掲示板投稿編集 ピン留めのオン/オフ切り替え
 */
export default class ChangePinned {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('#pinned')
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`);
		this._$e = $e;
		this._id = this._$e.attr('id');
		this._$target = $('.block-display-period-wrap');
		this._isInitialize = true;
		this._$e.on('change', () => this.onChange(300));
		this.onChange();
	};


	/**
	 * Clickのコールバック
	 *
	 * @memberof ChangePinned
	 * @return {undefined}
	 */
	onChange(duration = 0) {
		console.log(`${this.constructor.name}.onChange`);
		if(this._$e.prop('checked')){
			if(this._isInitialize) {
				this._isInitialize = false;
				this._$target.each((idx, e) => {
					$(e)
						.slideDown(duration)
						.find('.validate')
						.removeClass('ignore-validate');
				});
			} else {
				this._$target.each((idx, e) => {
					$(e)
						.slideDown(duration)
						.find('.validate')
						.removeClass('ignore-validate')
						.find('option[value="1"]')
						.prop('selected', 'selected');
				});
			}
		} else {
			if(this._isInitialize) this._isInitialize = false;
			this._$target.each((idx, e) => {
				$(e)
					.slideUp(duration)
					.find('.validate')
					.addClass('ignore-validate')
					.removeClass('is-invalid')
					.val('');
			})
			this._$target.each((idx, e) => {
				$(e)
					.find('.error-tip')
					.removeClass('show')
					.find('.validate')
					.html('.error-tip-inner')
					.html('');
			});
		}
	}
}
