/**
 * @file 文字切り替えだけ
 */
'use strict';

/**
 * @classname SeeMore
 * @classdesc SeeMore
 */
export default class SeeMore {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`);
		this._$e = $e;
		this._defaultText = this._$e.data('default-text');
		this._activatedText = this._$e.data('activated-text');
		this._$e.on('click', () => this.onClick());
	}


	/**
	 * クリックのコールバック
	 *
	 * @memberof SeeMore
	 * @return {undefined}
	 */
	onClick() {
		console.log(`${this.constructor.name}.onClick`);
		this._$e.toggleClass('is-see-more-active');
		this._$e.hasClass('is-see-more-active') ? this._$e.html(this._activatedText) : this._$e.html(this._defaultText)
	}
}
