/**
 * @file キャッチコピー
 *
 */
'use strict';
import Common from '../../Utils/Common';

/**
 * @classname CatchCopy
 * @classdesc キャッチコピー
 */
export default class CatchCopy {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('CatchCopy.constructor');
		this._$e = $e;
		const target = $e.attr('data-target');
		this._$target = $(target);
		this._$otherTarget = $('.block-catch-copy1');
		this._$otherDummy = $('.input-dummy1');
		if(target === '.block-catch-copy1'){
			this._$otherTarget = $('.block-catch-copy2');
			this._$otherDummy = $('.input-dummy2');
		}
		$e.on('click', () => this.onClick());
		this._$target.find('.catch_copy').on('click', e => this.onSelect(e));
		this._$target.find('.anchor-close-catch-copy').on('click', () => {
			this._$target.slideUp();
			this._$e.removeClass('open');
		})
	};

	/**
	 * clickのコールバック
	 *
	 * @memberof CatchCopy
	 * @return {undefined}
	 */
	onClick() {
		console.log('CatchCopy.onClick');
		if(this._$e.hasClass('open')){
			this._$target.slideUp();
			this._$e.removeClass('open');
		}else{
			this._$target.slideDown();
			this._$otherTarget.hide();
			this._$otherDummy.removeClass('open');
			this._$e.addClass('open');
		}
	}


	/**
	 * clickのコールバック
	 *
	 * @memberof CatchCopy
	 * @return {undefined}
	 */
	onSelect(e) {
		console.log('CatchCopy.onSelect');
		const $e = $(e.currentTarget);
		const text = $e.next('label').text();
		console.log(text);
		this._$e.text(text);
		$('.input-dummy1').removeClass('is-invalid');
		$('.input-dummy2').removeClass('is-invalid');
	};
}
