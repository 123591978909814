/**
 * @file PointTextWrap
 */
'use strict';

/**
 * @classname PointTextWrap
 * @classdesc PointTextWrap
 */
export default class PointTextWrap {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('PointTextWrap.constructor');
		this._$e = $e;
		$e.on('change', () => this.onChange());
	};


	/**
	 * changeのコールバック
	 *
	 * @memberof PointTextWrap
	 * @return {undefined}
	 */
	onChange() {
		console.log('PointTextWrap.onChange');
		const $wrap = $('.block-point-text-wrap');
		const $pointTextOther = $('#point_text_other');
		if(this._$e.val() === 'other'){
			$wrap.slideDown();
			$pointTextOther.removeClass('ignore-validate');
		}else{
			$wrap.slideUp().find('.error-tip').removeClass('show').children('.error-tip-inner').html('');
			$pointTextOther.removeClass('is-invalid').val('').addClass('ignore-validate');
		}
	};
}
