/**
 * @file FormPoint
 */
'use strict';

/**
 * @classname FormPoint
 * @classdesc 登録
 */
export default class FormPoint {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 */
	constructor(controller){
		this._controller = controller;
	};


	/**
	 * フォーム送信前処理
	 *
	 * @memberof FormRegistration
	 * @return {undefined}
	 */
	preProcessing($form) {
		console.log('FormPoint.preProcessing');
		// バリデーション後に行う処理を記載（開始）

		let checked = false;
		const $pointTarget = $('.point-target');
		let cnt = 0;
		$pointTarget.each((idx, e) => {
			const $e = $(e);
			if($e.prop('checked')) checked = false;
			cnt ++;
		});
		console.log(cnt);
		//$('.validate[type=checkbox]').addClass('is-invalid');

		// バリデーション後に行う処理を記載（終了）
		this._controller.completePreProcessing($form);
	};


	/**
	 * フォーム送信後処理
	 *
	 * @memberof FormPoint
	 * @return {undefined}
	 */
	postProcessing($form) {
		console.log('FormPoint.postProcessing');
		// バリデーション後に行う処理を記載（開始）

		
		// バリデーション後に行う処理を記載（終了）
		this._controller.completePostProcessing($form);
	};
}
