/**
 * @file マスク着用
 */
'use strict';

/**
 * @classname TakeMask
 * @classdesc マスク着用
 */
export default class TakeMask {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('TakeMask.constructor');
		this._$e = $e;
		this._$modal = $('#modal-mask');
		this._$photoImage = this._$modal.find('.image-box-detail-photo');
		this._$mainPhoto = $('.block-item-top').find('.image-box-detail-photo');
		$e.on('click', () => this.onClick());
		this._$modal.find('[name=mask_size]').on('click', e => this.changeSize(e));
		this._$modal.find('[name=mask_color]').on('click', e => this.changeColor(e));
		$('.btn-mask-set').on('click', () => this.setMask());
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof TakeMask
	 * @return {undefined}
	 */
	onClick() {
		console.log('TakeMask.onClick');
		this._$modal.addClass('show').show();
	};


	/**
	 * モーダルを消す
	 *
	 * @memberof TakeMask
	 * @return {undefined}
	 */
	hideModal() {
		console.log('TakeMask.hideModal');
		this._$modal.removeClass('show').hide();
	};


	/**
	 * サイズ変更
	 *
	 * @memberof TakeMask
	 * @return {undefined}
	 */
	changeSize(e) {
		console.log('TakeMask.changeSize');
		const $e = $(e.currentTarget);
		const klass = $e.val();
		this._$photoImage.removeClass('mask-normal').removeClass('mask-big').removeClass('mask-small');
		this._$photoImage.addClass(klass);
	};


	/**
	 * 色変更
	 *
	 * @memberof TakeMask
	 * @return {undefined}
	 */
	changeColor(e) {
		console.log('TakeMask.changeColor');
		const $e = $(e.currentTarget);
		const klass = $e.val();
		this._$photoImage.removeClass('mask-white').removeClass('mask-gray').removeClass('mask-pink');
		this._$photoImage.addClass(klass);
	};


	/**
	 * マスク着用
	 *
	 * @memberof TakeMask
	 * @return {undefined}
	 */
	setMask() {
		console.log('TakeMask.setMask');

		let sizeClass = 'mask-normal';
		if(this._$photoImage.hasClass('mask-big')){
			sizeClass = 'mask-big';
		}else if (this._$photoImage.hasClass('mask-small')){
			sizeClass = 'mask-small';
		}
		this._$mainPhoto.removeClass('mask-normal').removeClass('mask-big').removeClass('mask-small');
		this._$mainPhoto.addClass(sizeClass);
		let colorClass = 'mask-white';
		if(this._$photoImage.hasClass('mask-gray')){
			colorClass = 'mask-gray';
		}else if (this._$photoImage.hasClass('mask-pink')){
			colorClass = 'mask-pink';
		}
		this._$mainPhoto.removeClass('mask-white').removeClass('mask-gray').removeClass('mask-pink');
		this._$mainPhoto.addClass(colorClass);
		this.hideModal();
		this._$mainPhoto.append('<button type="button" class="btn btn-mask-off"><span>×</span></button>');
		$('.btn-mask-off').on('click',() => this.resetMask());
	};


	/**
	 * マスク外す
	 *
	 * @memberof TakeMask
	 * @return {undefined}
	 */
	resetMask() {
		console.log('TakeMask.resetMask');
		this._$mainPhoto
			.removeClass('mask-normal')
			.removeClass('mask-big')
			.removeClass('mask-small')
			.removeClass('mask-white')
			.removeClass('mask-gray')
			.removeClass('mask-pink');
		$('.btn-mask-off').remove();
	};
}
