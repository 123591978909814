/**
 * @file CommentDeleteItem
 */
'use strict';

/**
 * @classname CommentDeleteItem
 * @classdesc deleteItem
 */
export default class CommentDeleteItem {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('DeleteItem.constructor');

		this._$commentArticleId = $('.btn-idea-board-comment-delete');
		this._$commentArticleId.on('click', () => this.onClick());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof CommentDeleteItem
	 * @return {undefined}
	 */
	onClick() {
		console.log('CommentDeleteItem.onClick');
		$('#comment-delete-modal span.name').text(this._name);
		
		//削除ボタンにIDをセット
		const CommentArticleId = $('.btn-idea-board-comment-delete').data('article-id');
		$('.comment-delete-modal-input-hidden').val(CommentArticleId);
		
	}
}

