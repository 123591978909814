/**
 * @file DeleteItem
 */
'use strict';

/**
 * @classname DeleteItem
 * @classdesc deleteItem
 */
export default class DeleteItem {
	/**
	 * @constructor
	 * @param {jQuery} $e - .btn-delete-item
	 */
	constructor($e) {
		console.log('DeleteItem.constructor');
		this._name = $e.attr('data-name');
		this._articleId = $e.attr('data-article-id');
		this._action = $e.attr('data-action') || null;
		$e.on('click', () => this.onClick());

	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof DeleteItem
	 * @return {undefined}
	 */
	onClick() {
		console.log('DeleteItem.onClick');
		$('#delete-modal span.name').text(this._name);

		if(this._action) {
			$('#delete-form').attr('action', this._action);
		}

		//削除ボタンにIDをセット
		$('.delete-modal-input-hidden').val(this._articleId);

	};
}

