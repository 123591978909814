/**
 * @file 組織検索
 */
'use strict';
import Common from "../../Utils/Common";

/**
 * @classname OrganizationSearch
 * @classdesc 組織検索
 */
export default class OrganizationSearch {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('OrganizationSearch.constructor');
		this._$e = $e;
		$e.on('click', (e) => this.onClick(e));
	};

	/**
	 * clickのコールバック
	 *
	 * @memberof OrganizationSwitch
	 * @return {undefined}
	 */
	onClick(e){
		console.log('OrganizationSearch.onClick');
		let type = 'name';
		let url = Common.getCurrentURL();
		if(e.currentTarget.getAttribute('id')==='keyword') type = 'keyword';
		console.log(type);
		if(type === 'name'){
			const name =$('[data-search-type=name]').val();
			console.log(name);
			if(name === '') return;
			url = url + `?type=name&search=${name}`;
		}else{
			const keyword =$('[data-search-type=keyword]').val();
			if(keyword === '') return;
			if(keyword.length > 1){
				url = url + `?type=keyword&search=${keyword}`;
			}else{
				alert('最低2文字以上入力してください');
				return;
			}
		}
		console.log(url);
		location.href = url;
	}
}
