/**
 * @file FormModel Controller
 */

'use strict';
import Listener from '../Utils/Listener';
import Common from '../Utils/Common';

let instance = null;

/**
 * @classname FormModel
 * @classdesc Basic Model
 */
export default class FormModel {

	/**
	 * @constructor
	 */
	constructor() {
		if (instance) {
			return instance;
		}

		this._listener = new Listener();
		this._jqXHR = null;
		this._post = false;
		this._data = null;
		this._message = null;
		this._code = null;

		// csrf add
		$.ajaxSetup({
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			}
		});

		instance = this;
		return instance;
	};


	/**
	 * 「読んだよ！」ボタンのカウントを増やす
	 * 
	 * @memberof BasicModel
	 * @param {integer} articleId - 記事ID
	 * @return {undefined}
	 */
	incrementReactionCount(articleId) {
		if(this._post) return;
		console.log(`FormModel.incrementReactionCount`);
		Common.showLoading();
		this._post = true;
		let formData = new FormData();
		formData.append('id', articleId);
		let params = {
			data: formData,
			method: 'POST',
			dataType: 'json',
			url: window.const.API_URL.INCREMENT_REACTION_COUNT,
			processData: false,
			contentType: false
		};
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onIncrementReactionCountComplete.bind(this));
	};


	/**
	 * 「読んだよ！」ボタンのカウントを増やす ajax通信完了
	 *
	 * @memberof BasicModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	 onIncrementReactionCountComplete(data, textStatus) {
		console.log('BasicModel.onIncrementReactionCountComplete');
		Common.hideLoading();
		this._data = data.result;
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if(textStatus === 'success'){
			if(this._code !== 0){
				this._post = false;
				Common.showErrorModal(this._message);
			}else{
				this._post = false;
				this._listener.trigger('incrementReactionCountComplete');
			}
		}else{
			this._post = false;
			this._code = 9999;
			this._message = data.message || '通信エラーが発生しました。<br>再度お試しください。';
			Common.showErrorModal(this._message);
			console.log(`ajax error (onIncrementReactionCountComplete): ${textStatus}`);
		}
		this._jqXHR = null;
	};


	/**
	 * 掲示板お気に入り
	 *
	 * @memberof BasicModel
	 * @param {string} boardId - 掲示板記事ID
	 * @param {boolean} flg - 登録フラグ
	 * @return {undefined}
	 */
	favorite(boardId, flg) {
		if (this._post) return;
		console.log(`BasicModel.favorite`);
		Common.showLoading();
		this._post = true;
		let formData = new FormData();
		formData.append('board_id', boardId);
		let url;
		if(flg){
			url = window.const.API_URL.FAVORITE_ON;
		}else{
			url = window.const.API_URL.FAVORITE_OFF;
		}
		let params = {};
		params['data'] = formData;
		params['method'] = 'POST';
		params['dataType'] = 'json';
		params['url'] = url;
		params['processData'] = false;
		params['contentType'] = false;
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onFavoriteComplete.bind(this));
	};


	/**
	 * 掲示板お気に入り登録・解除完了
	 *
	 * @memberof BasicModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @param {number} xhr - XMLHttpRequest
	 * @return {undefined}
	 */
	onFavoriteComplete(data, textStatus, xhr) {
		console.log('BasicModel.onFavoriteComplete');
		Common.hideLoading();
		this._data = data.result;
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if (data.status == 422) {
			// laravel validate errorの時
			this._post = false;
			Common.showErrorModal(data.responseJSON.message);
			this._listener.trigger('onError');
		}else if (textStatus === 'success') {
			if (this._code !== 0) {
				this._post = false;
				Common.showErrorModal(this._message);
				this._listener.trigger('onError');
			} else {
				this._post = false;
				this._listener.trigger('onFavoriteComplete');
			}
		} else {
			this._post = false;
			this._code = 9999;
			this._message = `（${data.status}）<br>通信エラーが発生しました。<br>再度お試しください。`;
			Common.showErrorModal(this._message);
			console.log(`ajax error (onFavoriteComplete): ${textStatus}`);
			this._listener.trigger('onError');
		}
		this._jqXHR = null;
	};


	/**
	 * listener on
	 *
	 * @memberof FormModel
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	on(name, fn) {
		this._listener.on(name, fn);
	};


	/**
	 * listner off
	 *
	 * @memberof FormModel
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	off(name, fn) {
		this._listener.off(name, fn);
	};
}
