/**
 * @file オフィシャルジャンル選択時
 */
'use strict';

/**
 * @classname ChangeOfficialGenre
 * @classdesc オフィシャルジャンル選択時
 */
export default class ChangeOfficialGenre {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('.block-official-genre input.board-genre')
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`);
		this._$e = $e;
		this._hasAuthorOption = this._$e.attr('data-has-author-option');
		this._divisionId = this._$e.attr('data-division-id');
		this._$target = $('.block-author-option');
		this._$subAuthorOption = $('.block-sub-author-option');
		this._$e.on('change', () => this.onChange(300));
		this._$e.on('click', () => this.onClick());
		this.onChange();
	};


	/**
	 * コールバック
	 *
	 * @memberof ChangeOfficialGenre
	 * @return {undefined}
	 */
	 onChange(duration = 0) {
		console.log(`${this.constructor.name}.onChange`);
		console.log(`data-has-author-option: ${this._hasAuthorOption}`)
		if(!this._isInitialized) {
			console.log(`${this.constructor.name}.onChange / in initializing...`);
			if($('.block-official-genre input.board-genre:checked').attr('data-has-author-option')) {
				this._$target.each((idx, e) => {
					$(e).slideDown(duration)
				})
				$('.block-official-genre input.board-genre:checked').trigger('click');
			} else {
				this._$target.each((idx, e) => {
					$(e).slideUp(duration)
				});
			}
			this._isInitialized = true
		} else {
			console.log(`${this.constructor.name}.onChange / normal execution`);
			this._$target.find('input').prop('checked', false)
			this.resetSubAuthorOption()
			if(this._hasAuthorOption) {
				this._$target.each((idx, e) => {
					$(e).slideDown(duration)
				})
			} else {
				this._$target.each((idx, e) => {
					$(e).slideUp(duration)
				});
			}
		}
	}


	/**
	 * コールバック
	 *
	 * @memberof ChangeOfficialGenre
	 * @return {undefined}
	 */
	onClick() {
		console.log(`${this.constructor.name}.onClick`);
		if(this._hasAuthorOption) $('input.posting_as_division').attr('data-division-id', this._divisionId);
	}


	/**
	 * 部署選択をリセット
	 *
	 * @memberof ChangeOfficialGenre
	 * @return {undefined}
	 */
	resetSubAuthorOption() {
		console.log(`${this.constructor.name}.resetSubAuthorOption`);
		this._$subAuthorOption
			.slideUp()
			.find('.sub_posting_division')
			.each((index, element) => {
				$(element)
					.addClass('ignore-validate')
					.prop('checked', false)
			})
		this._$subAuthorOption
			.find('.error-tip')
			.removeClass('show')
			.find('.error-tip-inner')
			.html('')
	}
}
