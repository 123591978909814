/**
 * @file 商品モーダルの内容書き換え
 */
'use strict';

/**
 * @classname ExchangeModal
 * @classdesc 商品モーダルの内容書き換え
 */
export default class ExchangeModal {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('ExchangeModal.constructor');
		this._$e = $e;
		this._$prize_id = this._$e.attr('prize-id');
		// モーダル内の要素取得
		this._$modal = $('#modal-item');
		this._$modalTitle = this._$modal.find('.modal-title');
		this._$modalImg = this._$modal.find('.image');
		this._$modalDirect = this._$modal.find('.anchor-point-modal');
		this._$modalMyPoint = this._$modal.find('.my-point');
		this._$modalNecessaryPoint = this._$modal.find('.necessary-point');
		this._$modalQuantitiyPoint = this._$modal.find('.quantity-point');
		this._$textRedCaution = this._$modal.find('.text-red-caution');
		this._$applybtn = this._$modal.find('.btn-apply-modal');
		this._$modalCompleteText = this._$modal.find('.text-exchange-complete')
		// 書き換える内容取得
		this._$title = this._$e.closest('.list-item').find('.text-product-text').data('product-title');
		this._$myPoint = this._$e.closest('.area-2column').find('.text-my-point').data('my-point')
		this._$necessaryPoint = this._$e.closest('.list-item').find('.text-exchange-point').data('exchange-point')
		this._$image = this._$e.closest('.list-item').find('.image').attr('src');
		this._$direct = this._$e.closest('.list-item').find('.anchor-point');
		$e.on('click', () => this.onClick());
	};
 

	/**
	 * clickのコールバック
	 *
	 * @memberof ExchangeModal
	 * @return {undefined}
	 */
	onClick() {
		// $('#prize_id').val(this._$prize_id)
		$('.btn-apply-modal').attr({
			'prize-id': this._$prize_id,
		})
		console.log('ExchangeModal.onClick');
		console.log(this._$title);
		this._$modalTitle.text(this._$title);
		this._$modalImg.attr('src',this._$image);
		this._$modalMyPoint.text('保有ポイント : ' + this._$myPoint + ' GDP');
		this._$modalNecessaryPoint.text('必要ポイント : ' + this._$necessaryPoint + ' GDP');
		this._$applybtn.text(this._$e.text().substring(0,2) + "を確定する");
		this._$modalCompleteText.text(this._$e.text().substring(0,2) + "申請を受け付けました！");
		let quantityPoint = this._$myPoint - this._$necessaryPoint;
		if(0 <= quantityPoint) {
			this._$textRedCaution.addClass('hide')
			this._$modalQuantitiyPoint.removeClass('text-red-caution')
			this._$applybtn.attr('disabled', false)
		}else {
			this._$textRedCaution.removeClass('hide')
			this._$modalQuantitiyPoint.addClass('text-red-caution')
			this._$applybtn.addClass('btn-secondary')
			this._$applybtn.attr('disabled',true)
		}
		this._$modalQuantitiyPoint.html('応募後のポイント残高 : ' + quantityPoint + ' GDP');
		if(this._$direct.hasClass('direct')) {
			this._$modalDirect.addClass('direct')
		}else {
			this._$modalDirect.removeClass('direct')
		}
		console.log('Modal.onClick');
	};

}