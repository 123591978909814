/**
 * @file BtnSpSearch
*/
'use strict';

/**
  * @classname BtnSpSearch
  * @classdesc BtnSpSearch
  */
export default class BtnSpSearch {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`);
		this._$e = $e;
		this._$form = $(this._$e.data('form'));
		this._$input = $('#sp-search');
		$e.on('click', () => this.onClick());
		window.onresize = () => (window.innerWidth >= 992) && this.close();
	}
 
 
	/**
	 * クリックのコールバック
	 *
	 * @memberof BtnSpSearch
	 * @return {undefined}
	 */
	onClick() {
		console.log(`${this.constructor.name}.onClick`);
		(this._$form.hasClass('is-open') && this._$input.val()) && this._$form.trigger('submit')
		this._$form.toggleClass('is-open');
	}
 
 
	/**
	 * window.resizeのコールバック
	 *
	 * @memberof BtnSpSearch
	 * @return {undefined}
	 */
	close() {
		console.log(`${this.constructor.name}.close`);
		this._$form.removeClass('is-open');
	}
}
 