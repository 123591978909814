/**
 * @file FormSetUp
 */
'use strict';

/**
 * @classname FormSetUp
 * @classdesc 登録
 */
export default class FormSetUp {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 */
	constructor(controller){
		this._controller = controller;
	};


	/**
	 * フォーム送信前処理
	 *
	 * @memberof FormRegistration
	 * @return {undefined}
	 */
	preProcessing($form) {
		console.log('FormSetUp.preProcessing');
		// バリデーション後に行う処理を記載（開始）

		let newCatchCopy = false;
		const catchCopy1 = $('.input-dummy1').text();
		const catchCopy2 = $('.input-dummy2').text();
		if((catchCopy1 === '未選択' && catchCopy2 !== '未選択') || (catchCopy1 !== '未選択' && catchCopy2 === '未選択')){
			$('.input-dummy').addClass('is-invalid');
		}

		// バリデーション後に行う処理を記載（終了）
		this._controller.completePreProcessing($form);
	};


	/**
	 * フォーム送信後処理
	 *
	 * @memberof FormSetUp
	 * @return {undefined}
	 */
	postProcessing($form) {
		console.log('FormSetUp.postProcessing');
		// バリデーション後に行う処理を記載（開始）

		const name = `${$('#name1').val()} ${$('#name2').val()}`;
		$('#name').val(name);
		const name_kana = `${$('#name_kana1').val()} ${$('#name_kana2').val()}`;
		$('#name_kana').val(name_kana);

		const divId = $('#div_id').val();
		const deptId = $('#dept_id').val();
		console.log(divId, deptId);
		if(deptId === '' || !deptId){
			$('[name=department_id]').val(divId);
		}else{
			$('[name=department_id]').val(deptId);
		}

		const birthday = `${$('.year').val()}/${$('.year').val()}/${$('.year').val()}`;
		$('#birth_day').val(birthday);

		// バリデーション後に行う処理を記載（終了）
		this._controller.completePostProcessing($form);
	};
}
