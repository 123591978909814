/**
 * @file 組織説明文開閉
 */
'use strict';

import Common from "../../Utils/Common";

/**
 * @classname OrganizationSwitch
 * @classdesc 組織説明文開閉
 */
export default class OrganizationSwitch {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('OrganizationSwitch.constructor');
		this._$e = $e;
		if($e.hasClass('anchor-team')){
			this._$target = $e.closest('.text-team-name').next('.text-organization-info');
		}else{
			this._$target = $e.next('.text-organization-info');
		}
		$e.on('click', () => this.onClick());
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof OrganizationSwitch
	 * @return {undefined}
	 */
	onClick() {
		console.log('OrganizationSwitch.onClick');
		if(this._$e.hasClass('open')){
			if(this._$e.hasClass('anchor-team')){
				this._$e.removeClass('open').addClass('unopen').find('span').text('チームキーワード');
			}else{
				this._$e.removeClass('open').addClass('unopen').find('span').text('組織説明');
			}
			this._$target.slideUp();
		}else{
			this._$e.removeClass('unopen').addClass('open').find('span').text('閉じる');
			this._$target.slideDown();
		}
	};


	/**
	 * STARS選択
	 *
	 * @memberof OrganizationSwitch
	 * @return {undefined}
	 */
	select(e) {
		console.log('OrganizationSwitch.select');
		e.preventDefault();
		e.stopPropagation();
		const $e = $(e.currentTarget);
		const selected = $e.attr('data-color');
		this._$options.removeClass('active');
		this._$selected.find('.stars-select').removeClass('active');
		this._$selected.find(`.stars-select[data-color=${selected}]`).addClass('active');
		$('.stars-wrap')
			.removeClass('is-invalid')
			.closest('.form-content')
			.find('.error-tip')
			.removeClass('show')
			.find('.error-tip-inner')
			.html('');
	};
}
