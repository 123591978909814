/**
 * @file 会社からのお知らせクリック
 */
'use strict';

/**
 * @classname ChangeOfficial
 * @classdesc 会社からのお知らせクリック
 */
export default class ChangeOfficial {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`);
		this._$e = $e;
		this._$target = $('.block-pinned');
		this._$e.on('click', () => this.onClick());
		this.onClick();
	};


	/**
	 * Clickのコールバック
	 *
	 * @memberof ChangeOfficial
	 * @return {undefined}
	 */
	onClick() {
		console.log(`${this.constructor.name}.onClick`);
		if(this._$e.prop('checked')){
			this._$target.slideDown();
		}else{
			this._$target.slideUp();
			$('#pinned').prop('checked', false);
			$('.block-display-period-wrap').hide().find('.display_period').addClass('ignore-validate').removeClass('is-invalid').val();
		}
	};
}
