/**
 * @file BoardGenre
 */
'use strict';

/**
 * @classname BoardGenre
 * @classdesc BoardGenre
 */
export default class BoardGenre {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('BoardGenre.constructor');
		this._$e = $e;
		this._target = this._$e.data('target');
		$e.on('click', () => this.onClick());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof BoardGenre
	 * @return {undefined}
	 */
	onClick() {
		console.log('BoardGenre.onClick');

		let official = false;
		let cnt = 0;
		const $official = $('#official');
		const $officialBoard = $('.block-official-genre .validate');
		const $normalBoard = $('.block-normal-genre .validate');
		if($official.prop('checked')){
			official = true;
			$officialBoard.each((idx, e) => {
				const $e = $(e);
				$e.removeClass('is-invalid');
				if($e.prop('checked')) cnt ++;
			});
		}else{
			$normalBoard.each((idx, e) => {
				const $e = $(e);
				$e.removeClass('is-invalid');
				if($e.prop('checked')) cnt ++;
			});
		}
		if(cnt > 3){
			if(official){
				$officialBoard.addClass('is-invalid');
				$('.block-official-genre .error-tip').addClass('show').find('.error-tip-inner').html('<p>ジャンルは3つまでしか選択できません。</p>');
			}else{
				$normalBoard.addClass('is-invalid');
				$('.block-normal-genre .error-tip').addClass('show').find('.error-tip-inner').html('<p>ジャンルは3つまでしか選択できません。</p>');
				$('[data-accordion-type="target"]').each((idx, e) => {
					const $e = $(e);
					if($e.find('.board-genre').prop('checked')) {
						$e.slideDown();
						$e.prev('.anchor-accordion').find('.fas').removeClass('fa-chevron-down').addClass('fa-chevron-up');
					}
				});
			}
		}else if(cnt === 0){
			if(official){
				$officialBoard.addClass('is-invalid');
				$('.block-official-genre .error-tip').addClass('show').find('.error-tip-inner').html('<p>ジャンルが選択されていません。</p>');
			}else{
				$normalBoard.addClass('is-invalid');
				$('.block-normal-genre .error-tip').addClass('show').find('.error-tip-inner').html('<p>ジャンルが選択されていません。</p>');
			}
		}else{
			if(official){
				$('.block-official-genre .error-tip').removeClass('show').find('.error-tip-inner').text('');
			}else{
				$('.block-normal-genre .error-tip').removeClass('show').find('.error-tip-inner').text('');
			}
		}
	};
}
