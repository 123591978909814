/**
 * @file BusinessBoardCheckbox
 */
"use strict";

/**
 * @classname BusinessBoardCheckbox
 * @classdesc ALL選択時に他をdisabledにする
 */
export default class BusinessBoardCheckbox {
	/**
	 * @constructor
	 * @param {jQuery} $e - #dept_name-all
	 */
	constructor($e) {
		console.log("BusinessBoardCheckbox.constructor");
		this._$e = $e;
		$e.on("click", () => this.onClick());

		this.defaultCheckbox();
	}

	defaultCheckbox() {
		console.log('BusinessBoardCheckbox.defaultCheckbox');

		this._$defaultCheckAll = $('#dept_name-all');
		if(this._$defaultCheckAll.prop('checked')){
			$(".dept_id").prop("checked", false).prop("disabled", true);
			$(".dept_id").addClass("is-disabled");
		}else{
			$(".dept_id").prop("disabled", false);
		};
	}

	/**
	 * クリックのコールバック
	 *
	 * @memberof BusinessBoardCheckbox
	 * @return {undefined}
	 */
	onClick() {
		console.log("BoardGenre.onClick");
		if (this._$e.prop("checked")) {
			$(".dept_id").prop("checked", false).prop("disabled", true);
			$(".dept_id").removeClass("is-invalid");
			$(".dept_id").addClass("is-disabled");
			// エラーメッセージを削除
			$(".error-tip-checkbox")
				.removeClass("show")
				.find(".error-tip-inner")
				.text("");
		} else {
			$(".dept_id").prop("disabled", false);
		}
	}
}
