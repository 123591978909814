// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-gallery {
    padding: 20px 0;
}

.image-gallery textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #e4e4e4;
    border-radius: 3px;
    outline: none;
    font-size: 14px;
    height: 100px;
    resize: none;
    display: flex;
}

.textarea-hide {
    display: none !important;
}`, "",{"version":3,"sources":["webpack://./mock/src/js/Libs/editorjs/image-gallery/index.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,eAAe;IACf,aAAa;IACb,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".image-gallery {\n    padding: 20px 0;\n}\n\n.image-gallery textarea {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #e4e4e4;\n    border-radius: 3px;\n    outline: none;\n    font-size: 14px;\n    height: 100px;\n    resize: none;\n    display: flex;\n}\n\n.textarea-hide {\n    display: none !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
