/**
 * @file CheckAll
 */
'use strict';

/**
 * @classname CheckAll
 * @classdesc CheckAll
 */
export default class CheckAll {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('CheckAll.constructor');
		this._$e = $e;
		this._target = this._$e.data('target');
		$e.on('click', () => this.onClick());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof CheckAll
	 * @return {undefined}
	 */
	onClick() {
		console.log('CheckAll.onClick');
		$(this._target).removeClass('is-invalid');
		if(this._$e.prop('checked')){
			$(this._target).each((idx, e) => {
				const $e = $(e);
				$e.prop('checked', true);
			});
		}else{
			$(this._target).each((idx, e) => {
				const $e = $(e);
				$e.prop('checked', false);
			});
		}
	};
}
