/**
 * @file Basic Controller
 */

'use strict';
import Listener from '../Utils/Listener';
import BasicModel from '../Model/BasicModel';
import Common  from '../Utils/Common';

let instance = null;

/**
 * @classname Basic
 * @classdesc Basic Controller
 */
export default class Basic {

	/**
	 * @constructor
	 */
	constructor() {
		if (instance) {
			return instance;
		}
		console.log('Basic.constructor');

		this._model = new BasicModel();

		this._listener = new Listener();

		this._boardId = null;

		this._model.on('incrementReactionCountComplete', () => {
			this._incrementReactionCountResponse = this._model._data;
			this._listener.trigger('incrementReactionCountComplete');
		});
		this._model.on('onFavoriteComplete', () => {
			this._listener.trigger('onFavoriteComplete');
		});

		instance = this;
		return instance;
	};


	/**
	 * 「読んだよ！」ボタンのカウントを増やす
	 *
	 * @memberof Basic
	 * @param {integer} articleId - 記事ID
	 * @return {undefined}
	 */
	incrementReactionCount(articleId) {
		console.log('Basic.incrementReactionCount');
		this._model.incrementReactionCount(articleId);
	};

	/**
	 * 掲示板お気に入り
	 *
	 * @memberof Basic
	 * @param {string} boardId - 掲示板記事ID
	 * @param {boolean} flg - 登録フラグ
	 * @return {undefined}
	 */
	favorite(boardId, flg) {
		console.log(`Basic.favoriteOn: boardId: ${boardId}`);
		this._boardId = boardId;
		this._model.favorite(boardId, flg);
	};

	/**
	 * listener on
	 *
	 * @memberof Basic
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	on(name, fn) {
		this._listener.on(name, fn);
	};


	/**
	 * listener off
	 *
	 * @memberof Basic
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	off(name, fn) {
		this._listener.off(name, fn);
	};
}
