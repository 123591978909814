/**
 * @file 商品モーダルの内容書き換え
 */
'use strict';

/**
 * @classname CompleteModal
 * @classdesc モダール内容書き換え
 */
export default class CompleteModal {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('CompleteModal.constructor');
		this._$e = $e;
		$e.on('click', () => this.onClick());
	};
 

	/**
	 * clickのコールバック
	 *
	 * @memberof CompleteModal
	 * @return {undefined}
	 */
	onClick() {
		console.log("reload")
		window.location.reload()
		console.log('CompleteModal.onClick');
	};
}