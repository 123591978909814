/**
 * @file Accordion
 */
'use strict';

/**
 * @classname Accordion
 * @classdesc Accordion
 */
export default class Accordion {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`);
		this._$e = $e;
		this._$emitter = this._$e.find('[data-accordion-type="emitter"]');
		this._$target = this._$e.find('[data-accordion-type="target"]');
		this._$icon = this._$emitter.find('i.fa-plus') || null;
		this._$icon2 = this._$emitter.find('i.fa-chevron-down') || null;
		this._$emitter.on('click', () => this.onClick(300));
	}


	/**
	 * クリックのコールバック
	 *
	 * @memberof Accordion
	 * @return {undefined}
	 */
	onClick(duration = 0) {
		console.log(`${this.constructor.name}.onClick`);
		this._$target.stop().slideToggle(duration);
		if(this._$icon) this._$icon.toggleClass('fa-plus fa-minus');
		if(this._$icon2) this._$icon2.toggleClass('fa-chevron-down fa-chevron-up');
	}
}
