/**
 * @file ページネーション
 */
'use strict';

import Common from "../../Utils/Common";

/**
 * @classname Pagination
 * @classdesc ページネーション
 */
export default class Pagination {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('Pagination.constructor');
		this._$e = $e;
		$e.on('click', () => this.onClick());
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof Pagination
	 * @return {undefined}
	 */
	onClick() {
		if (this._$e.parent('.page-item').hasClass('active')) return;
		if (this._$e.parent('.page-item').hasClass('disabled')) return;
		console.log('Pagination.onClick');
		const page = this._$e.attr('data-page');
		let url = Common.getCurrentURL();
		console.log(url);
		let param = '';
		let params = Common.getParam();
		if(Object.keys(params).length > 0) {
			params['page'] = page;
			let prefix = '?';
			for(const key in params){
				param += `${prefix}${key}=${params[key]}`;
				prefix = '&';
			}
		} else {
			param = `?page=${page}`;
		}
		console.log(`${url}${param}`);
		location.href = `${url}${param}`;
	};

}
