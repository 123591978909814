/**
 * @file タブ
 */
'use strict';

import Common from "../../Utils/Common";

/**
 * @classname Tab
 * @classdesc タブ
 */
export default class Tab {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('Tab.constructor');
		this._$e = $e;
		this._$target = $(`#${$e.attr('aria-controls')}`);
		$e.on('click', () => this.onClick());
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof Tab
	 * @return {undefined}
	 */
	onClick() {
		if(this._$e.hasClass('active')) return;
		console.log('Tab.onClick');
		$('.nav-link').removeClass('active');
		this._$e.addClass('active');
		$('.tab-pane').removeClass('show').removeClass('active');
		this._$target.addClass('show').addClass('active');
	};
}
