/**
 * @file ジャンル「譲ります・譲って下さい」をチェックしたときに警告モーダル表示
 */
 'use strict';

 /**
  * @classname AssignmentModalView
  * @classdesc ジャンル「譲ります・譲って下さい」をチェックしたときに警告モーダル表示
  */
 export default class AssignmentModalView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	 constructor($e) {
		console.log('AssignmentModalView.constructor');
		this._$e = $e;
		this._target = $e.attr('data-target');
		this._$modal = $(this._target);
		$e.on('click', (e) => this.onClick(e));
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof Modal
	 * @return {undefined}
	 */
	onClick(e) {
		console.log('AssignmentModalView.onClick');
		const $e = e.currentTarget;
		if($e.checked) {
			this._$modal.addClass('show').show();
		}
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof Modal
	 * @return {undefined}
	 */
	hide() {
		console.log('AssignmentModalView.hide');
		this._$modal.removeClass('show').hide();
	};
 }