/**
 * @file CheckBoxToggle
 */
'use strict';

/**
 * @classname CheckBoxToggle
 * @classdesc CheckBoxToggle
 */
export default class CheckBoxToggle {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('CheckBoxToggle.constructor');
		this._$e = $e;
		this._$official = $('.block-official-genre');
		this._$normal = $('.block-normal-genre');
		$e.on('click', () => this.onClick());
		this.onClick();
		this.checkAccordion();
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof CheckBoxToggle
	 * @return {undefined}
	 */
	onClick() {
		console.log('CheckBoxToggle.onClick');
		if(this._$e.prop('checked')){
			this._$official.slideDown().find('.validate').removeClass('ignore-validate');
			this._$normal.slideUp().find('.validate').addClass('ignore-validate').removeClass('is-invalid').prop('checked', false);
			this._$normal.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
		}else{
			this._$official.slideUp().find('.validate').addClass('ignore-validate').removeClass('is-invalid').prop('checked', false);
			this._$official.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
			this._$normal.slideDown().find('.validate').removeClass('ignore-validate');
		}
	};


	/**
	 * 開閉確認
	 *
	 * @memberof CheckBoxToggle
	 * @return {undefined}
	 */
	checkAccordion() {
		console.log('CheckBoxToggle.checkAccordion');
		this._$normal.find('.board-genre').each((idx, e) => {
			const $e = $(e);
			if($e.prop('checked')){
				$e.closest('.block-item').slideDown();
				const $wrap = $e.closest('[data-accordion-type="wrap"]');
				$wrap.find('[data-accordion-type="emitter"]').find('.fas').removeClass('fa-chevron-down').addClass('fa-chevron-up');
				$wrap.find('[data-accordion-type="target"]').slideDown();
			}
		});
	};
}
