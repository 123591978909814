/**
 * @file 日付変換
 *
 */
'use strict';
import Common from '../../Utils/Common';

/**
 * @classname ChangeDateView
 * @classdesc 日付変換
 */
export default class ChangeDateView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('ChangeDateView.constructor');
		const $wrap = $e.closest('.form-content');
		this._$year = $wrap.find('.year');
		this._$month = $wrap.find('.month');
		this._$day = $wrap.find('.day');
		$e.on('change', () => {this.onChange();});
	};

	/**
	 * changeのコールバック
	 *
	 * @memberof ChangeDateView
	 * @return {undefined}
	 */
	onChange() {
		console.log('ChangeDateView.onChange');
		const year = parseInt(this._$year.val());
		const month = parseInt(this._$month.val());
		console.log(year, month);
		if(year && month){
			const day = Common.getDateFromYearMonth(year, month);
			console.log(day);
			let options = '<option value="">--</option>';
			for(let i = 1; i <= day; i++){
				options += `<option value="${Common.zeroPadding(i,2)}">${Common.zeroPadding(i,2)}</option>`;
			}
			this._$day.html(options);
		}else{
			this._$day.html('<option value="">--</option>');
		}
	}
}
