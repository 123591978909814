/**
 * @file Select2Wide
 */
'use strict';
import { select2 } from '../../Libs/select2.min'
 
/**
 * @classname Select2Wide
 * @classdesc Select2Wide
 */
export default class Select2Wide {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`);
		$e.select2({
			width: 500
		});
		$e.closest('.input-select').css({
			'max-width': 'none',
			'width': '500px'
		});
	};
}
