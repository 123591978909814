/**
 * @file AnchorSpFilter
 */
'use strict';

/**
 * @classname AnchorSpFilter
 * @classdesc AnchorSpFilter
 */
export default class AnchorSpFilter {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`);
		this._$e = $e;
		this._$target = this._$e.closest('[data-accordion-type="wrap"]').find('[data-accordion-type="target"]');
		if(window.innerWidth < 992) this._$target.slideUp(0);
		window.onresize = () => (window.innerWidth >= 992) && this._$target.slideDown(0);
	}
}
