"use strict";

// utility class
import ApplyClass from "../Utils/ApplyClass";

import Form from "../Controller/Form";

import Validate from "../View/Form/Validate";
import Submit from "../View/Form/Submit";
import ZenkakuToHankaku from "../View/Form/ZenkakuToHankaku";
import HasCounter from "../View/Form/HasCounter";
import Select2 from "../View/Form/Select2";
import Select2Wide from "../View/Form/Select2Wide";
import DatePicker from "../View/Form/DatePicker";
import DeleteItem from "../View/Form/DeleteItem";
import CommentDeleteItem from "../View/Form/CommentDeleteItem";
import PointTextWrap from "../View/Form/PointTextWrap";
import CheckAll from "../View/Form/CheckAll";
import CatchCopy from "../View/Form/CatchCopy";
import ChangePrizeType from "../View/Form/ChangePrizeType";
import ChangeDisplayType from "../View/Form/ChangeDisplayType";
import ChangeStockType from "../View/Form/ChangeStockType";
import DeletePrizePeriod from "../View/Form/DeletePrizePeriod";
import ChangeDoubleChancePrizeType from "../View/Form/ChangeDoubleChancePrizeType";
import DeleteDoubleChance from "../View/Form/DeleteDoubleChance";
import ChangePointType from "../View/Form/ChangePointType";
import CheckBoxToggle from "../View/Form/CheckBoxToggle";
import Editor from "../View/Form/Editor";
import BusinessboardEditor from "../View/Form/BusinessboardEditor";
import BusinessboardCheckbox from "../View/Form/BusinessboardCheckbox";
import Preview from "../View/Form/Preview";
import BusinessboardCommentPreview from "../View/Form/BusinessboardCommentPreview";
import BusinessboardCommentEdit from "../View/Form/BusinessboardCommentEdit";
import TempSave from "../View/Form/TempSave";
import BoardGenre from "../View/Form/BoardGenre";
import BusinessBoardGenre from "../View/Form/BusinessBoardGenre";
import ChangeOfficial from "../View/Form/ChangeOfficial";
import DatepickerClose from "../View/Form/DatepickerClose";
import BtnPost from "../View/Form/BtnPost";
import ImageRotate from "../View/Form/ImageRotate";
import ChangeRotate from "../View/Form/ChangeRotate";

import UploadPhoto from "../View/Form/UploadPhoto";
import DeletePhoto from "../View/Form/DeletePhoto";
import ApplyModal from "../View/Form/ApplyModal";
import AssignmentModalView from "../View/Form/AssignmentModalView";
import SwapContributor from "../View/Form/SwapContributor";

// for staff
import ChangeDept from "../View/Form/ChangeDept";
import ChangeTeam from "../View/Form/ChangeTeam";
import ChangeBirthday from "../View/Form/ChangeBirthday";
import ChangeDate from "../View/Form/ChangeDate";
import DeletePostedArticle from "../View/Form/DeletePostedArticle";
import ChangePinned from "../View/Form/ChangePinned";
import ChangeOfficialGenre from "../View/Form/ChangeOfficialGenre";
import CheckPossibilityToPostAsADivision from "../View/Form/CheckPossibilityToPostAsADivision";
import TogglePassword from "../View/Form/TogglePassword";

$(() => {
	const FormController = new Form();
	// validate
	$(".validate").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Validate, $e, [$e, FormController]);
	});
	// 全角→半角変換
	$(".zen2han").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ZenkakuToHankaku, $e, [$e, FormController]);
	});
	// カウンター
	$(".has-counter").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(HasCounter, $e, [$e, FormController]);
	});
	// submit
	$("[type=submit][data-form]").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Submit, $e, [$e, FormController]);
	});
	// select2
	$(".select2").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Select2, $e, [$e, FormController]);
	});
	// select2
	$(".select2-wide").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Select2Wide, $e, [$e, FormController]);
	});
	// Air  Datepicker
	$("[data-datepicker=true]").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(DatePicker, $e, [$e, FormController]);
	});
	// アイテム削除
	$(".btn-delete-item").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(DeleteItem, $e, [$e, FormController]);
	});
	// コメント削除
	$(".btn-idea-board-comment-delete").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(CommentDeleteItem, $e, [$e, FormController]);
	});
	// ポイント付与テキスト変更
	$(".point_text").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(PointTextWrap, $e, [$e, FormController]);
	});
	// 全てチェックする
	$(".check-all").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(CheckAll, $e, [$e, FormController]);
	});
	// 全てチェックする
	$(".input-dummy").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(CatchCopy, $e, [$e, FormController]);
	});
	// 表示タイプ変更
	$(".display_type").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ChangeDisplayType, $e, [$e, FormController]);
	});
	// 在庫タイプ変更
	$(".stock_type").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ChangeStockType, $e, [$e, FormController]);
	});
	// 応募期間削除
	$(".btn-delete-prize-period").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(DeletePrizePeriod, $e, [$e, FormController]);
	});
	// ダブルチャンス賞品タイプ変更
	$(".double_chance_prize_type").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ChangeDoubleChancePrizeType, $e, [$e, FormController]);
	});
	$(".point_type").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ChangePointType, $e, [$e, FormController]);
	});
	// CheckBoxToggle
	$("#official").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(CheckBoxToggle, $e, [$e, FormController]);
	});
	// 応募期間削除
	$(".btn-delete-double-chance").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(DeleteDoubleChance, $e, [$e, FormController]);
	});
	//  写真アップロード
	$(".file-upload").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(UploadPhoto, $e, [$e, FormController]);
	});
	//  写真削除
	$(".btn-delete-photo").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(DeletePhoto, $e, [$e, FormController]);
	});
	// 部変更
	$(".change_dept").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ChangeDept, $e, [$e, FormController]);
	});
	// チーム変更
	$(".change_team").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ChangeTeam, $e, [$e, FormController]);
	});
	// 誕生日表示・非表示
	$("[name=show_birthday]").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ChangeBirthday, $e, [$e, FormController]);
	});
	// 誕生日
	$(".change-date").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ChangeDate, $e, [$e, FormController]);
	});
	// 賞品タイプ変更 ※ChangeXxxx系よりあとに記述する
	$(".prize_type").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ChangePrizeType, $e, [$e, FormController]);
	});
	// ポイント、交換、応募用モーダル
	$(".btn-apply-modal").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ApplyModal, $e, [$e, FormController]);
	});
	// EditorJS
	$("#codex-editor").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Editor, $e, [$e, FormController]);
	});
	$("#businessboard-editor").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(BusinessboardEditor, $e, [$e, FormController]);
	});
	// 掲示板プレビュー
	$(".btn-preview").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Preview, $e, [$e, FormController]);
	});
	$(".BusinessboardComment").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(BusinessboardCommentPreview, $e, [$e, FormController]);
	});
	// アイデア交流板コメント編集
	$(".block-comment-post").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(BusinessboardCommentEdit, $e, [$e, FormController, idx]);
	});
	// 掲示板プレビュー
	$("#tempSave").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(TempSave, $e, [$e, FormController]);
	});
	// 掲示板ジャンル選択
	$(".board-genre").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(BoardGenre, $e, [$e, FormController]);
	});
	$(".page-idea-board-create .dept_id").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(BusinessBoardGenre, $e, [$e, FormController]);
	});
	// 投稿記事削除
	$(".btn-delete-posted-article").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(DeletePostedArticle, $e, [$e, FormController]);
	});
	// 会社からのお知らせ
	$("#official").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ChangeOfficial, $e, [$e, FormController]);
	});
	// datepickerクリア
	$(".btn-datepicker-close").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(DatepickerClose, $e, [$e, FormController]);
	});
	// 掲示板ピン留めcheckbox
	$("#pinned").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ChangePinned, $e, [$e, FormController]);
	});
	// 掲示板投稿
	$(".btn-post").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(BtnPost, $e, [$e, FormController]);
	});
	// 掲示板画像回転
	$("[data-rotate=90]").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ImageRotate, $e, [$e, FormController]);
	});
	$("[data-rotate=270]").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ImageRotate, $e, [$e, FormController]);
	});
	// オフィシャルジャンル選択時
	$(".block-official-genre input.board-genre").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ChangeOfficialGenre, $e, [$e, FormController]);
	});
	// 部としての投稿にできるか
	$(".block-author-option").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(CheckPossibilityToPostAsADivision, $e, [
			$e,
			FormController,
		]);
	});
	// サムネイル回転
	$("#rotate").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ChangeRotate, $e, [$e, FormController]);
	});
	// パスワードの表示・非表示切り替え
	$(".btn-toggle-password").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(TogglePassword, $e, [$e, FormController]);
	});
	// ジャンル「譲ります・譲って下さい」をチェックしたときに警告モーダル表示
	$("[data-assignment-modal=true]").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(AssignmentModalView, $e, [$e, FormController]);
	});
	// 投稿者入れ替えのチェックボックス
	$(".swap-contributor").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(SwapContributor, $e, [$e, FormController]);
	});
	// 投稿者入れ替えのチェックボックス
	$("#dept_name-all").each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(BusinessboardCheckbox, $e, [$e, FormController]);
	});
});
