/**
 * @file 文字カウンター
 */
'use strict';

/**
 * @classname ChangeDept
 * @classdesc 文字カウンター
 */
export default class ChangeDept {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('ChangeDept.constructor');
		this._$e = $e;
		this._$deptWrap = $('.block-dept-wrap');
		$e.on('change', () => this.onChange());
	};


	/**
	 * changeのコールバック
	 *
	 * @memberof ChangeDept
	 * @return {undefined}
	 */
	onChange() {
		console.log('ChangeDept.onChange');
		const val = this._$e.val();
		const noDept = this._$e.find('option:selected').attr('data-no-dept');
		if(val === ''){
			this._$deptWrap.slideUp().find('.validate').html('').removeClass('is-invalid').closest('.form-group').find('.error-tip').removeClass('show').find('.error-tip-inner').html('');
		}else{
			let options = '';
			if(noDept === 'false'){
				// 部がある場合
				options = '';
				let first = true
				let teamOptions = '';
				for(let i = 0; i < baseDept.length; i++){
					const dept = baseDept[i];
					if(dept.parent_id === parseInt(val)){
						options += `<option value="${dept.value}" data-parent-id="${dept.parent_id}">${dept.name}</option>`;
						if(first){
							for(let j= 0; j < baseTeam.length; j++) {
								const team = baseTeam[j];
								if (team.parent_id === dept.value) {
									teamOptions += `<option value="${team.value}">${team.name}</option>`;
									first = false;
								}
							}
							$('.team_id').html(teamOptions).trigger('change');
						}
					}
				}
				$('.dept_id').html(options);
				this._$deptWrap.slideDown();
			}else{
				// 部がない場合
				this._$deptWrap.slideUp();
				$('.dept_id').html('');
				for(let i = 0; i < baseTeam.length; i++){
					const team = baseTeam[i];
					if(team.parent_id === parseInt(val)){
						options += `<option value="${team.value}">${team.name}</option>`;
					}
				}
				$('.team_id').html(options).trigger('change');
			}
		}
	};
}
