/**
 * @file 掲示板プレビュー
 */
"use strict";
import Common from "../../Utils/Common";

/**
 * @classname Preview
 * @classdesc 掲示板プレビュー
 */
export default class Preview {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log("Preview.constructor");
		this._$e = $e;
		this._controller = controller;
		this._$modal = $("#modal-preview");
		$e.on("click", () => this.onClick());
	}

	/**
	 * クリックのコールバック
	 *
	 * @memberof Preview
	 * @return {undefined}
	 */
	onClick() {
		console.log("Preview.onClick");
		this._controller.createHtml();
		setTimeout(() => {
			if ($("#html").val() !== "") {
				$("[name=mode]").val("2");
				console.log($("#mode"));
				const $formBoard = $("#form-board");
				$formBoard.attr("target", "_blank");
				setTimeout(() => {
					Common.submit($("#form-board"));
				}, 500);
				setTimeout(() => {
					$("[name=mode]").val("1");
				}, 3000);
				// $('.modal .heading-user-title').text($('#title').val());
				// let genre = '';
				// $('.board-genre').each((idx, e) => {
				// 	const $e = $(e);
				// 	if($e.prop('checked')){
				// 		genre += `<a class="anchor" href="${window.const.URL.BOARD_GENRE_URL}?genre=${$e.val()}"><span class="badge badge-secondary">${$e.attr('data-title')}</span></a>`;
				// 	}
				// });
				// $('.modal .text-genre').html(genre);
				// const dt = new Date();
				// let today = `${dt.getFullYear()}/${Common.zeroPadding(dt.getMonth() + 1, 2)}/${Common.zeroPadding(dt.getDate(), 2)} ${Common.zeroPadding(dt.getHours(), 2)}:${Common.zeroPadding(dt.getMinutes(), 2)}`
				// $('.modal .text-date').html(today);
				// this._$modal.addClass('show').show();
				// $('[data-rotate=90], [data-rotate=270]').each((idx, e) => {
				// 	const $e = $(e);
				// 	const width = $e.width();
				// 	const height = $e.height();
				// 	console.log(width, height);
				// 	const scale = width / height;
				// 	const wrapperHeight = Math.ceil(width * scale);
				// 	const margin = (height - wrapperHeight) / -2;
				// 	let style = $e.attr('style');
				// 	style = `${style}margin-top:${margin}px;margin-bottom:${margin}px;`;
				// 	$e.attr('style', style);
				// });
			} else {
				Common.showErrorModal("本文を設定してください。");
			}
		}, 500);
	}
}
