/**
 * @file アイデア交流板コメント編集
 */
"use strict";
import Common from "../../Utils/Common";
import Modal from "../Basic/Modal";

/**
 * @classname BusinessboardCommentEdit
 * @classdesc コメント編集
 */
export default class BusinessboardCommentEdit {
	/**
	 * @constructor
	 * @param {jQuery} $e - .block-comment-post
	 */

	constructor($e, controller, idx) {
		console.log("BusinessboardCommentEdit.constructor");
		this._$e = $e;

		this._$ideaBoardCommentText = this._$e.find(".text-idea-board-comment-text");
		this._$ideaBoardCommentImg = this._$e.find(".image-idea-board-comment-img");
		this._$ideaBoardCommentEdit = this._$e.find(".btn-idea-board-comment-edit");
		this._$ideaBoardCancel = this._$e.find(".businessboard-cancel");
		this.index = idx;
		this._$inputImage = $("#inputImage");
		this.imageUrl = "";
		this._$modal = $("#modal-comment");
		this._$ideaBoardCommentEdit.on("click", (e) => this.onClick(e));

		this._$isVisibleEdit = false;
	}

	onClick(e) {
		console.log("BusinessboardCommentEdit.onClick");
		const $e = $(e.currentTarget);
		console.log($e);
		const action = $e.attr('data-action');

		// コメントのテキスト、画像を取得
		const ideaBoardCommentText = this._$ideaBoardCommentText.text();
		this.ideaBoardCommentImgSrc = this._$ideaBoardCommentImg.attr("src") || null;

		// 編集エリアを表示中はfalseになる
		if (this._$isVisibleEdit) {
			const _$commentEditForm = $(`.comment-edit-form-${this.index}`);

			_$commentEditForm.remove();
		} else {
			const token = $('[name=csrf-token]').attr('content');
			// モーダルに表示するHTMLを生成
			let previewHTML = `
				<form action="${action}" class="form-horizontal mt20 comment-edit-form comment-edit-form-${this.index}" method="post" accept-charset="utf-8" enctype="multipart/form-data">
					<textarea class="mb10 input-textarea" name="comment" cols="90" rows="4" style="width: 100%;">${ideaBoardCommentText}</textarea>
					<input type="hidden" name="_token" value="${token}">
					<div class="block-item block-item-btn-area">
						<div class="block-item block-item-btn-attached">
							<label class="btn-attached">
								<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill="#808080">
									<path d="M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z" />
								</svg> 画像添付ファイル
								<input type="file" name="html" value="" accept="image/*" class="inputImage">
							</label><a class="btn btn-clear attached-image-clear" href="javascript:void(0);">クリア</a>
						</div>
						<div class="block-item block-item-btn-comment">
							<button class="btn btn-secondary businessboard-cancel" type="button">キャンセル</button>
							<button class="btn btn-primary btn-comment btn-idea-board-comment-edit" type="button" data-form=".comment-edit-form-${this.index}">コメントする</button>
						</div>
					</div>`;
			if(this.ideaBoardCommentImgSrc) previewHTML +=
					`<img class="image image-attached-image attached-image" src="${this.ideaBoardCommentImgSrc}">`;
			previewHTML += '</form>';
			this._$e.after(previewHTML);

			this._$commentForm = $(`.comment-edit-form-${this.index}`)
			this._$inputImage = this._$commentForm.find(".inputImage");
			this._$commentEditBtn = this._$commentForm.find(".btn-idea-board-comment-edit");
			this._$clearBtn = this._$commentForm.find(".attached-image-clear");
			this._$modalTarget = this._$commentForm.find("[data-modal=true]");
			this._$ideaBoardCommentTextEdit = this._$commentForm.find(".input-textarea");
			this._$attachedImage = this._$commentForm.find(".image-attached-image");
			this._$ideaBoardCancel = this._$commentForm.find(".businessboard-cancel");

			this.imageUrl = this._$attachedImage.attr("src");

			this._$clearBtn.on("click", () => this.onClearBtnClick());
			console.log("this", this._$clearBtn);
			this._$commentEditBtn.on("click", () => this.onEditBtnClick());
			this._$inputImage.on("change", (e) => this.onChange(e));

			this._$ideaBoardCancel.on("click", () => this.onCancelClick());
		}

		this._$isVisibleEdit = !this._$isVisibleEdit;
	}

	/**
	 * クリックのコールバック
	 *
	 * @memberof BusinessboardCommentEdit
	 * @return {undefined}
	 */
	onEditBtnClick() {
		console.log("BusinessboardCommentEdit.onClick");
		// フォームの本文を取得
		this._comment = this._$ideaBoardCommentTextEdit.val();

		// モーダルに表示するHTMLを生成
		if(this._comment === ''){
			Common.showErrorModal('コメントが未記入です。');
			return;
		}else{
			let previewHTML = "";
			if (this._$inputImage.val()) {
				previewHTML = `
					<p class="text">${this._comment}</p>
					${
					this.imageUrl &&
					`<img src="${this.imageUrl}" alt="イメージ" width="300px" />`
				}	
			`;
			} else {
				previewHTML = `<p class="text">${this._comment}</p>`;
			}
			// モーダルのコンテンツにHTMLを挿入
			this._$modal.find(".modal-body").html(previewHTML);
			this._$modal.find('.btn-primary').attr('data-form', `.comment-edit-form-${this.index}`);

			// モーダルを表示
			this._$modal.addClass('show').show();
		}
	}

	onChange(e) {
		console.log("BusinessboardCommentEdit.onChange");
		console.log(e);

		// 1. 画像ファイルの読み込みクラス
		const reader = new FileReader();

		// 2. 読み込んだ画像ファイルをURLに変換
		if (this._$inputImage.val()) {
			console.log('no.2 in');
			reader.readAsDataURL(e.target.files[0]);
		}

		// 3. 準備が終わったら、id=sample1のsrc属性に選択した画像ファイルの情報を設定
		if (!this._$inputImage.val()) {
			this._$attachedImage.remove();
		}else{
			console.log('no.3 in');
			console.log(this._$attachedImage);
			if(!this._$commentForm.find('.image-attached-image')[0]){
				console.log('append');
				this._$commentForm.append(`<img class="image image-attached-image attached-image" src="">`)
				this._$attachedImage = this._$commentForm.find('.image-attached-image');
			}
		}

		if (e.target.files.length !== 0) {
			reader.onload = (e) => {
				this.onLoad(e);
			};
		}
	}
	onLoad(e) {
		console.log("BusinessboardCommentEdit.onLoad");
		this._$attachedImage.attr("src", e.target.result);
		this.imageUrl = e.target.result;
		console.log(this.imageUrl);
		setTimeout(() => {
			this._$attachedImage.attr("src", this.imageUrl);
		},500);
	}
	// クリア処理
	onClearBtnClick() {
		console.log("BusinessboardCommentEdit.onClearBtnClick");
		console.log("this", this._$attachedImage);
		this._$attachedImage.attr("src", "");
		this._$inputImage.val("");
		const modalHTML = `<p class="text">${this._comment}</p>`;
		if(!this._$commentForm.find('input.delete_flg')[0]){
			this._$commentForm.append('<input type="hidden" class="delete_flg" name="delete" value="1">');
		}

		this._$modal.find(".modal-body").html(modalHTML);
	}

	/**
	 * キャンセルボタンをクリックした時の処理
	 *
	 * @memberof BusinessboardCommentEdit
	 * @return {undefined}
	 */
	// クリックイベントのハンドラ内で引数を使わないように修正
	onCancelClick() {
		console.log("BusinessboardCommentEdit.onCancelClick");

		// キャンセルボタンをクリックしたときの処理
		const $commentEditForm = $(`.comment-edit-form-${this.index}`);
		$commentEditForm.hide();
	}
}
