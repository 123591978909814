/**
 * @file CheckPossibilityToPostAsADivision
 */
'use strict';

/**
 * オフィシャルジャンルのonChangeでthis._inputのdata-division-idに
 * オフィシャルジャンルで選択した部の配下にある部署のidリストが入る。
 * this._inputはdata-my-division-id属性を持ち、
 * ここにはbladeでこの画面を開いているユーザーが所属している部署のidリストが入る。
 * data-my-division-idのうちのいずれかがdata-division-idに含まれていたら、
 * 「部としての投稿にする」が可能になる。
 * 
 * 「部としての投稿にする」にチェックが入った場合、
 * data-my-division-idのうちdata-division-idに含まれたいたものすべてについて、
 * どの部署からの投稿にするか選択できる部署選択のラジオボタンを生成する。
 * 「部としての投稿にする」のチェックが外れた場合、
 * 部署選択のラジオボタンを削除し、rowを非表示にする。
 * 
 * @classname CheckPossibilityToPostAsADivision
 * @classdesc CheckPossibilityToPostAsADivision
 */
export default class CheckPossibilityToPostAsADivision {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('.block-author-option')
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`);
		this._$e = $e;
		this._input = this._$e.find('.posting_as_division');
		this._$subAuthorOption = $('.block-sub-author-option');
		this._isInitialized = false;
		this._$e.find('.input-checkbtn-label').on('click', (e) => this.onClick(e));
		this.onClick()
	};


	/**
	 * division_id と my_division_id が等しいか確認して返す
	 *
	 * @memberof CheckPossibilityToPostAsADivision
	 * @return {boolean}
	 */
	checkPossibility() {
		console.log(`${this.constructor.name}.checkPossibility`);
		this._possibility = false;
		this._divisionId = this._input.attr('data-division-id').toString().split(',');
		this._myDivisionId = this._input.attr('data-my-division-id').toString().split(',');
		this._divisionId.forEach(id => {
			if(this._myDivisionId.includes(id)) this._possibility = true;
		});
		return this._possibility;
	}


	/**
	 * クリックのコールバック
	 *
	 * @memberof CheckPossibilityToPostAsADivision
	 * @return {undefined}
	 */
	onClick(e) {
		console.log(`${this.constructor.name}.onClick`);
		console.log(`division_id: ${this._input.attr('data-division-id').toString()} / my_division_id: ${this._input.attr('data-my-division-id').toString().split(',')}`)
		if(this.checkPossibility()) {
			if (!this._isInitialized) {
				this._isInitialized = true
			} else {
				this._input.prop('checked', !this._input.prop('checked'))
			}
			this._$e.find('.error-tip').removeClass('show')
			if (this._input.prop('checked')) {
				this.changeRadioButtonsVisibility()
				this._$subAuthorOption
					.slideDown()
					.find('.sub_posting_division')
					.each((index, element) => $(element).removeClass('ignore-validate'))
			} else {
				this.resetSubAuthorOption()
			}
		} else {
			this._input.prop('checked', false)
			this._$e.find('.error-tip').addClass('show').find('.error-tip-inner').text('投稿者がジャンルで選択した本部に所属している場合のみ選択できます。')
			this.resetSubAuthorOption()
		}
	};


	/**
	 * 「部としての投稿にする」が選択状態となった場合、選択された本部配下の部のうち、
	 * 自身が所属する部署を選択できるラジオボタンを表示する。
	 *
	 * @memberof CheckPossibilityToPostAsADivision
	 * @return {undefined}
	 */
	changeRadioButtonsVisibility() {
		console.log(`${this.constructor.name}.changeRadioButtonsVisibility`)
		this._divisionId = this._input.attr('data-division-id').toString().split(',')
		this._myDivisionId = this._input.attr('data-my-division-id').toString().split(',')
		this._matchedDivisionIds = this._myDivisionId.filter(myDivisionId => this._divisionId.includes(myDivisionId))
		console.log(`this._matchedDivisionIds: ${this._matchedDivisionIds}`)
		let checkedCount = 0
		this._$subAuthorOption.find('.sub_posting_division').each((_, element) => {
			if ($(element).prop('checked')) checkedCount++
			$(element).closest('.input-radio').addClass('d-none')
			if (this._matchedDivisionIds.includes($(element).val())) {
				$(element).closest('.input-radio').removeClass('d-none')
			}
		})
		if (checkedCount === 0) {
			let $visibleElements = []
			this._$subAuthorOption.find('.sub_posting_division').each((_, element) => {
				if (!$(element).closest('.input-radio').hasClass('d-none')) $visibleElements.push($(element))
			})
			if ($visibleElements.length) $visibleElements[0].prop('checked', true)
		}
	}


	/**
	 * 部署選択をリセット
	 *
	 * @memberof ChangeOfficialGenre
	 * @return {undefined}
	 */
	resetSubAuthorOption() {
		console.log(`${this.constructor.name}.resetSubAuthorOption`);
		this._$subAuthorOption
			.slideUp()
			.find('.sub_posting_division')
			.each((index, element) => {
				console.log($(element))
				$(element)
					.addClass('ignore-validate')
					.prop('checked', false)
			})
		this._$subAuthorOption
			.find('.error-tip')
			.removeClass('show')
			.find('.error-tip-inner')
			.html('')
	}
}
