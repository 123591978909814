/**
 * @file 読んだよ！ボタン
 */
'use strict';

/**
 * @classname ArticleReaction
 * @classdesc 読んだよ！ボタン
 */
export default class ArticleReaction {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('.btn-article-reaction')
	 */
	constructor($e, controller) {
		console.log(`${this.constructor.name}.constructor`);
		this._$e = $e;
		this._controller = controller;
		this._state = this._$e.hasClass('is-active');
		this._$e.on('click', () => this.onClick());
		this._controller.on('incrementReactionCountComplete', () => this.onIncrementReactionCountComplete());
	};
 
 
	/**
	 * クリックのコールバック
	 *
	 * @memberof ArticleReaction
	 * @return {undefined}
	 */
	onClick() {
		if(this._state) return;
		console.log(`${this.constructor.name}.onClick`);
		this._controller.incrementReactionCount($('input.article_id').val());
	};
 
 
	/**
	 * クリックのコールバック
	 *
	 * @memberof ArticleReaction
	 * @return {undefined}
	 */
	onIncrementReactionCountComplete() {
		console.log(`${this.constructor.name}.onIncrementReactionCountComplete`);
		this._state = true;
		this._$e.addClass('is-active');
		this._$e.find('.span-count').text(this._controller._incrementReactionCountResponse.reaction_count);
		$('.drawer-sp-menu .list-current-state .list-item:nth-child(2) p.text').text(`${this._controller._incrementReactionCountResponse.point}GDP`);
		$('.list-header-mypage-menu > .list-item:first-of-type p.text').text(`${this._controller._incrementReactionCountResponse.point}GDP`);
	};
}
 