/**
 * @file FormOrganizationOrder
 */
'use strict';

/**
 * @classname FormOrganizationOrder
 * @classdesc 登録
 */
export default class FormOrganizationOrder {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 */
	constructor(controller){
		this._controller = controller;
	};


	/**
	 * フォーム送信前処理
	 *
	 * @memberof FormRegistration
	 * @return {undefined}
	 */
	preProcessing($form) {
		console.log('FormOrganizationOrder.preProcessing');
		// バリデーション後に行う処理を記載（開始）


		// バリデーション後に行う処理を記載（終了）
		this._controller.completePreProcessing($form);
	};


	/**
	 * フォーム送信後処理
	 *
	 * @memberof FormOrganizationOrder
	 * @return {undefined}
	 */
	postProcessing($form) {
		console.log('FormOrganizationOrder.postProcessing');
		// バリデーション後に行う処理を記載（開始）

		let division = [];
		let divisionOrder = 1;
		$('#div > .list-item').each((idx, e) => {
			const $e = $(e);
			let tmp = {};
			const id = $e.children('.block-organization').attr('data-id');
			tmp = {
				id: id,
				order: divisionOrder,
				depts: ''
			};
			if($e.find('.list-organization')[0]){
				let deptOrder = 1;
				let depts = [];
				let tmp2;
				$e.find('.list-organization .block-organization').each((idx, e) => {
					const $dept = $(e);
					const deptId = $dept.attr('data-id');
					tmp2 = {
						id: deptId,
						order: deptOrder
					}
					depts.push(tmp2);
					deptOrder ++;
				});
				tmp['depts'] = depts;
			}
			division.push(tmp);
			divisionOrder ++;
		});

		console.log(division);

		$('#organization').val(JSON.stringify(division));
		
		// バリデーション後に行う処理を記載（終了）
		this._controller.completePostProcessing($form);
	};
}
