/**
 * @file 掲示板プレビュー
 */
"use strict";
import Common from "../../Utils/Common";

/**
 * @classname BusinessboardCommentPreview
 * @classdesc コメントプレビュー
 */
export default class BusinessboardCommentPreview {
	/**
	 * @constructor
	 * @param {jQuery} $e - .BusinessboardComment
	 */
	constructor($e) {
		console.log("BusinessboardCommentPreview.constructor");
		this._$e = $e;
		this._$modal = $("#modal-comment");
		this._$inputImage = $(".inputImage");
		this.imageUrl = "";
		this._form = $e.attr('data-form');
		this._$clearBtn = $(".attached-image-clear");
		this._$attachedImage = $(".attached-image");
		this._$e.on("click", () => this.onInputImgClick());
		this._$clearBtn.on("click", () => this.onClearBtnClick());
		this._$inputImage.on("change", (e) => this.onChange(e));
	}

	/**
	 * クリックのコールバック
	 *
	 * @memberof BusinessboardCommentPreview
	 * @return {undefined}
	 */
	onInputImgClick() {
		console.log("BusinessboardCommentPreview.onClick");
		// フォームの本文を取得
		const comment = $(".input-textarea").val();

		// モーダルに表示するHTMLを生成
		if(comment === ''){
			Common.showErrorModal('コメントが未記入です。');
			return;
		}else{
			let previewHTML = "";
			if (this._$inputImage.val()) {
				previewHTML = `
					<p class="text">${comment}</p>
					${
					this.imageUrl &&
					`<img src="${this.imageUrl}" alt="イメージ" width="300px" />`
				}		
			`;
			} else {
				previewHTML = `<p class="text">${comment}</p>`;
			}
			// モーダルのコンテンツにHTMLを挿入
			this._$modal.find(".modal-body").html(previewHTML);
			this._$modal.find('.btn-primary').attr('data-form', this._form);

			// モーダルを表示
			this._$modal.addClass('show').show();
		}
	}

	onChange(e) {
		// 2. 画像ファイルの読み込みクラス
		const reader = new FileReader();
		// let tempImageUrl = ''
		// 3. 準備が終わったら、id=sample1のsrc属性に選択した画像ファイルの情報を設定
		reader.onload = (e) => {
			this.onLoad(e);
		};
		if (!this._$inputImage.val()) {
			this._$attachedImage.attr("src", "");
		}
		// 4. 読み込んだ画像ファイルをURLに変換
		if (this._$inputImage.val()) {
			reader.readAsDataURL(e.target.files[0]);
		}

		// });
	}
	onLoad(e) {
		if (this._$inputImage.val()) {
			this._$attachedImage.attr("src", e.target.result);
			this.imageUrl = e.target.result;
		} else {
			this._$attachedImage.attr("src", "");
			this.imageUrl = "";
		}

		// console.log('image',this.imageUrl);
	}

	onClearBtnClick() {
		this._$attachedImage.attr("src", "");
		this._$inputImage.val("");
	}
}
