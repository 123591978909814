/**
 * @file FormUserStaff
 */
'use strict';

/**
 * @classname FormUserStaff
 * @classdesc 登録
 */
export default class FormUserStaff {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 */
	constructor(controller){
		this._controller = controller;
	};


	/**
	 * フォーム送信前処理
	 *
	 * @memberof FormRegistration
	 * @return {undefined}
	 */
	preProcessing($form) {
		console.log('FormUserStaff.preProcessing');
		// バリデーション後に行う処理を記載（開始）

		// バリデーション後に行う処理を記載（終了）
		this._controller.completePreProcessing($form);
	};


	/**
	 * フォーム送信後処理
	 *
	 * @memberof FormUserStaff
	 * @return {undefined}
	 */
	postProcessing($form) {
		console.log('FormUserStaff.postProcessing');
		// バリデーション後に行う処理を記載（開始）

		const divId = $('#div_id').val();
		const deptId = $('#dept_id').val();
		console.log(divId, deptId);
		if(deptId === '' || !deptId){
			$('[name=department_id]').val(divId);
		}else{
			$('[name=department_id]').val(deptId);
		}


		// バリデーション後に行う処理を記載（終了）
		this._controller.completePostProcessing($form);
	};
}
