/**
 * @file 一時保存
 */
"use strict";
import Common from "../../Utils/Common";

/**
 * @classname TempSave
 * @classdesc 一時保存
 */
export default class TempSave {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log("TempSave.constructor");
		this._$e = $e;
		this._controller = controller;
		$e.on("click", () => this.onClick());
	}

	/**
	 * クリックのコールバック
	 *
	 * @memberof TempSave
	 * @return {undefined}
	 */
	onClick() {
		console.log("TempSave.onClick");
		this._controller.createHtml();
		$("#form-board").removeAttr("target");
		$("[name=mode]").val("0");
		console.log($("#mode"));
		setTimeout(() => {
			Common.submit($("#form-board"));
		}, 500);
	}
}
